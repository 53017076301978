import React, { Component } from "react";
import Logo from "../Anasayfa/Logo/Logo";
import Hakkiicerik from "./Hakkiicerik";

export default class Hakki extends Component {
  render() {
    return (
      <div >
        <Logo />
        <Hakkiicerik />
      </div>
    );
  }
}
