import React, { Component } from 'react';
import Loftvalentine from '../componentArabic/Loftvalentine/Loftvalentineone';
import Logo from '../componentArabic/Anasayfa/Logo/Logo';
import Navbarjs from '../componentArabic/Anasayfa/navbar/Navbarjs';
import Navbermobil from '../componentArabic/Navbarmabil/Navbermobil';
import Mobilfooter from '../componentArabic/mobilfooter/Mobilfooter';
import Mobililetisim from '../componentArabic/İletisim/Mobililetisimsiyah';
import MobilLogo from '../componentArabic/Anasayfa/mobillogo/MobilLogo'
import Loftvalentinemobil from '../componentArabic/Loftvalentine/Loftvalentinemobil';
import Linkler from '../container/Linkler';

export default class Loftvalentinepage extends Component {
  render() {
    return (
    <div className='hepsi'>
      <Navbermobil/>
      <MobilLogo/>
        <Logo/>
        <Linkler/>
        <Loftvalentine/>
        <Loftvalentinemobil/>
        <Navbarjs/>
        <Mobililetisim/>
        <Mobilfooter/>
        

        
    </div>);
  }
}
