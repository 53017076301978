import React, { Component } from 'react';
import Logoo from './images/logo.png'
import Bayrak from './images/ingiltere.jpg'
import airanbayrak from './images/iranbayrak.png'
import Rusbayrak from './images/rusbayrak.png'
import Turkbayrak from './images/turkbayrak.png'
import Arabicbayrak from './images/arabicbayrak.png'
import { Link } from 'react-router-dom'
import './style.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCameraReelsFill } from "react-icons/bs";
import PopupReklam from '../poppup/PopupReklam';
export default class Logo extends Component {
  render() {
    return <div className='upbar'>
      <center><div className='logo'><img src={Logoo}/></div></center>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'><PopupReklam/></div>
          <div className='col-lg-4'><center>   </center></div>
          <div className='col-lg-4'><div className='ee'>  
          <div className='altlink'>
  <Link to='/'style={{ textDecoration: 'none' }}><b> <img src={Turkbayrak}></img></b></Link>
  <Link to='/anasayfa/en'style={{ textDecoration: 'none' }}><b> <img src={Bayrak}></img></b></Link>
      <Link to='/anasayfa/rus'style={{ textDecoration: 'none' }}><b> <img src={Rusbayrak}></img></b></Link>
      <Link to='/anasayfaarabic'style={{ textDecoration: 'none' }}><b> <img src={Arabicbayrak}></img></b></Link>
      </div>
   </div><p className='solup'>&nbsp;0532 694 00 79</p></div>
        </div>
      </div>
     
    
  
    </div>
    ;
  }
}
