import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import Poppupp from '../poppup/Poppupp';
import Bilgiform from './Bilgiform';
import { AiFillHome } from "react-icons/ai";
import 'reactjs-popup/dist/index.css';
import Resimone from './images/Group 254.png'
import Popupgizlilik from '../poppup/Popupgizlilik';



export default class Navbarjs extends Component {
    render() {
        return (
           
          <div className='navbarr'>
              
          <div className='Gizlilik'><Popupgizlilik/></div>
          <div className='logolar'><img src={Resimone}/></div>

      
          <div className='yazi'>
          <Link to='/'style={{textDecoration: 'none',  }}><h5><AiFillHome/></h5> </Link>
          <Link to='/hakkimizda'style={{ textDecoration: 'none' }}><h5>Hakkımızda</h5></Link>
          <Link to='/loftvalentine'style={{ textDecoration: 'none' }}><h5>Loft Valentine</h5></Link>
          <Link to='/katplani'style={{ textDecoration: 'none' }}><h5>Kat Planları</h5></Link>
          <Link to='/gorsel'style={{ textDecoration: 'none' }}><h5>Görseller</h5></Link>
          <Link to='/iletisim'style={{ textDecoration: 'none' }}><h5>İletişim </h5></Link>
        
          </div>
      </div>
        )
    }
}

