import React, { Component } from 'react'
import Navbarjs from '../componentRus/Anasayfa/navbar/Navbarjs'
import Header from '../componentRus/Anasayfa/Header/Header'
import Logo from '../componentRus/Anasayfa/Logo/Logo'
import Navbermobil from '../componentRus/Navbarmabil/Navbermobil'
import Mobililetisim from '../componentRus/İletisim/Mobilhomeiletisim'
import Mobilfooter from '../componentRus/mobilfooter/Mobilfooter'
import MobilLogo from '../componentRus/Anasayfa/mobillogo/MobilLogo'
import Linkler from '../container/Linkler rus'


export default class Home extends Component {
    render() {
        return (
            <div className='homemobil'> 
<MobilLogo/>
<Linkler/>
                <Navbermobil/>
                <Logo/>
                <Navbarjs/>
                <Header/>
                <Mobililetisim/>
                <Mobilfooter/>
            </div>
        )
    }
}
