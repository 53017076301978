import React, { useEffect, useState } from 'react';
import './resim.css';
import '../../App.css';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import Resim from './images/Group 255.png'

const images = [
//{id:'1', imageName:'LV GÜNDÜZ 2-1.jpg', tag:'Gece'},
{id:'4', imageName:'LV GECE 2-2.jpg', tag:'НАРУЖНЫЙ'},
{id:'5', imageName:'LV GECE 2-1.jpg', tag:'НАРУЖНЫЙ'},
{id:'6', imageName:'LV GECE 2-3.jpg', tag:'НАРУЖНЫЙ'},
  {id:'7', imageName:'LV GÜNDÜZ 2-1.jpg', tag:'НАРУЖНЫЙ'},
  {id:'8', imageName:'LV GÜNDÜZ 2-2.jpg', tag:'НАРУЖНЫЙ'},
  {id:'9', imageName:'LV GÜNDÜZ 2-3.jpg', tag:'НАРУЖНЫЙ'},
  {id:'10', imageName:'LV GÜNDÜZ 2-4.jpg', tag:'НАРУЖНЫЙ'},
  {id:'11', imageName:'LV GÜNDÜZ 2-5.jpg', tag:'НАРУЖНЫЙ'},
  
  {id:'15', imageName:'iç-görsel.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'16', imageName:'iç-görsel-2.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'17', imageName:'iç-görsel-3.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'18', imageName:'iç-görsel-4.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'19', imageName:'iç-görsel-5.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'20', imageName:'iç-görsel-6.jpeg', tag:'ИНТЕРЬЕР'},
  {id:'21', imageName:'iç-görsel-7.jpeg', tag:'ИНТЕРЬЕР'},
];

const options = {
	// settings: {
	// 	overlayColor: 'rgb(25, 136, 124)',
	// 	autoplaySpeed: 1500,
	// 	transitionSpeed: 900
	// },
	// buttons: {
	// 	backgroundColor: 'red',
	// 	iconColor: 'rgba(126, 172, 139, 0.8)'
	// },
	// caption: {
	// 	captionColor: '#a6cfa5',
	// 	captionFontFamily: 'Raleway, sans-serif',
	// 	captionFontWeight: '300',
	// 	captionTextTransform: 'uppercase'
	// },
	// progressBar: {
	// 	height: '20px',
	// 	fillColor: 'blue',
	// 	backgroundColor: 'white'
	// }
};

function Resimboxs() {
  const [tag, setTag] = useState('НАРУЖНЫЙ');
	const [filteredImages, setFilteredImages] = useState([]);

	useEffect(
		() => {
			tag === 'gGece' ? setFilteredImages(images) : setFilteredImages(images.filter(image => image.tag === tag));
		},
		[tag]
	);

	return (
    <div className='container-fluid gorseltum'>
		<div className='row'>
			<div className='col-lg-2 col-sm-12 sekil'>
				<img src={Resim}/>
			</div>
			<div className='col-lg-2 col-sm-12 secenekler'>
		
		<TagButton name="НАРУЖНЫЙ" tagActive={tag === 'DIŞ MEKAN' ? true: false } handleSetTag={setTag} /> 
		<TagButton name="ИНТЕРЬЕР" tagActive={tag === 'İÇ MEKAN' ? true: false } handleSetTag={setTag} />

	</div>
			<div className='col-lg-8 col-sm-12'>

			<SimpleReactLightbox>

	
	<SRLWrapper options={options}>
		<div className="containerr">
  
			{filteredImages.map(image => (
				<div key={image.id} className="image-card">
					<a href={`/images/${image.imageName}`}>
						<img className="image" src={`/images/${image.imageName}`} alt="" />
					</a>
				</div>
			))}
		</div>
	</SRLWrapper>

</SimpleReactLightbox>

			</div>
		</div>
	</div>
	);
}

const TagButton = ({ name, handleSetTag, tagActive }) => {
	return (
		<button className={`tag ${tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>
			{name.toUpperCase()}
		</button>
	);
};


export default Resimboxs;
