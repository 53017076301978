import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import '../Logo/style.css'
import Resim from './images/Component 24 – 7.png'
import { Link } from 'react-router-dom'
import İnforesim from './images/web.gif'

export default class İnfo extends Component {
  

  render() {
    
    return (
      <center> <div className='infoo'>
        <div className='infovideoo'>
       <img  src={İnforesim}/>
    </div>
    <div className="inforesimm">
    <Link to='/anasayfa'style={{ textDecoration: 'none' }}><img src={Resim}/> </Link>
      
    </div>
    
    </div></center>
      
    )
  }
}
