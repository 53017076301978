import React from 'react'
import './styles.css'
import { BsWhatsapp } from "react-icons/bs";

import { IoIosCall } from "react-icons/io";

import Popuplinkmail from '../component/Anasayfa/poppup/Popuplinkmail';
import ReactWhatsapp from 'react-whatsapp';
export default function Linkler() {
  <ReactWhatsapp number="0-531-913-4063" message="Hello World!!!" />
  return (
    <div className='linklere'>
     <h3><a href="https://wa.me/9005326940079?text=" target="_blank"><BsWhatsapp/></a></h3>
        <h3 className='arkah3'> <a href="Tel:02129092828" target="_blank"><IoIosCall/></a></h3>
        <h3><Popuplinkmail/></h3>
    </div>
  )
}
