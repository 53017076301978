import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Lft from './component/lftval/lftval'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Katplanı from './pages/Kat';
import Gorsel from './pages/Görsel';
import Hakkimizda from './pages/Hakkimizda';
import İletisim from './pages/İletisimpage';
import Loftvalentinepage from './pages/Loftvalentinepage';
import İnfoHome from './pages/İnfohome'


//Arabic
import HomeArabic from './pages arabic/Home';
import HakkimizdaArabic from './pages arabic/Hakkimizda';
import Loftvalentinepagearabic from './pages arabic/Loftvalentinepagearabic';
import Gorselarap from './pages arabic/Görsel';
import Katplaniarap from './pages arabic/Kat';
import İlesimarabic from './pages arabic/İletisimpage'

//Rus
import HomeRus from './pages Rus/Home'
import HakkiicerikRus from './pages Rus/Hakkimizda';
import Loftvalentinerus from './pages Rus/Loftvalentinepage'
import Gorselrus from './pages Rus/Görsel';
import Katplanirus from './pages Rus/Kat';
import İletisimrus from './pages Rus/İletisimpage'



//En

import HomeEn from './pages En/Home'
import Abount from './pages En/Hakkimizda'
import Loftvalentineren from './pages En/Loftvalentinepage'
import Görselen from './pages En/Görsel'
import İletisimen from './pages En/İletisimpage'
import Katplaniing from './pages En/Kat'

//Fas

import HomeFas from './pages Fas/Home'
import Hakkimizdafas from './pages Fas/Hakkimizda'
import LoftvalentineFas from './pages Fas/Loftvalentinepage'
import Katplanifas from './pages Fas/Kat'
import Gorselfas from './pages Fas/Görsel'
import İletisimfas from './pages Fas/İletisimpage'


function App() {
  return (
    <div className="App">
 <Router>
        <Routes>
        <Route path="/" element={<Home/>}/>
        
        <Route path="/katplani" element={<Katplanı/>}/>
        <Route path="/gorsel" element={<Gorsel/>}/>
        <Route path="/loftvalentine" element={<Loftvalentinepage/>}/>
        <Route path="/hakkimizda" element={<Hakkimizda/>}/>
        <Route path="/iletisim" element={<İletisim/>}/>
        <Route path="/lft" element={<Lft/>}/>


        <Route path="/anasayfaarabic" element={<HomeArabic/>}/>
        <Route path="/hakkimizdaarabic" element={<HakkimizdaArabic/>}/>
        <Route path="Loftvalentinepagearabic" element={<Loftvalentinepagearabic/>}/>
        <Route path="/gorselarabic" element={<Gorselarap/>}/>
        <Route path="/katplaniarabic" element={<Katplaniarap/>}/>
        <Route path="/iletisimarabic" element={<İlesimarabic/>}/>


        
        <Route path="/anasayfa/rus" element={<HomeRus/>}/>
        <Route path="/hakkimizda/rus" element={<HakkiicerikRus/>}/>
        <Route path="/Loftvalentine/rus" element={<Loftvalentinerus/>}/>
        <Route path="/gorsel/rus" element={<Gorselrus/>}/>
        <Route path="/katpalani/rus" element={<Katplanirus />}/>
        <Route path="/iletisim/rus" element={<İletisimrus/>}/>
        
        
        <Route path="/anasayfa/en" element={<HomeEn/>}/>
        <Route path="/hakkimizda/en" element={<Abount/>}/>
        <Route path="/Loftvalentine/en" element={<Loftvalentineren/>}/>
        <Route path="/gorsel/en" element={<Görselen/>}/>
        <Route path="/iletisim/en" element={<İletisimen/>}/>
        <Route path="/katplani/en" element={<Katplaniing/>}/>

       

        <Route path="/anasayfa/fas" element={<HomeFas/>}/>
        <Route path="/hakkimizda/fas" element={<Hakkimizdafas/>}/>
        <Route path="/loftvalentine/fas" element={<LoftvalentineFas/>}/>
        <Route path="/katplani/fars" element={<Katplanifas/>}/>
        <Route path="/gorsel/fars" element={<Gorselfas/>}/>
        <Route path="/iletisim/fars" element={< İletisimfas/>}/>
        </Routes>
      </Router>
   
    </div>
  );
}

export default App;
