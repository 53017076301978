import React, { Component } from 'react'
import Popup from 'reactjs-popup';

import './style.css'
export default class Popupgizlilik extends Component {
  render() {
    return (
        <div className=''><Popup trigger={<b>اتفاق السرية</b>} position="right center">
        <div className='gizlilikyazi'>
          <div className='scrol'>
           <h5>عنا معلومات
</h5><p>
تهتم LOFT VALENTINE بحماية خصوصيتك الشخصية. تحدد سياسة الخصوصية هذه أنواع المعلومات التي تجمعها LOFT VALENTINE على هذا الموقع وكيفية حمايتها وتخزينها. لا تجمع LOFT VALENTINE معلومات شخصية عن أي مستخدم لموقع LOFT VALENTINE ما لم يتم تقديمها طواعية من قبل الزائر كقاعدة عامة ، لا يتم مشاركة البيانات الشخصية (الاسم وعنوان البريد الإلكتروني وأرقام الهواتف) المقدمة إلى موقعنا من قبل زوارنا في نطاق سياسة الخصوصية هذه مع أطراف ثالثة أو بيعها أو تأجيرها أو استخدامها بأي طريقة أخرى.


</p><p>
جمع المعلومات الشخصية باستخدام موقع LOFT VALENTINE وتقديم المعلومات الشخصية طواعية يعني أن سياسة الخصوصية الحالية والشروط المعلنة على هذا الموقع مقبولة.


</p><p>معلوماتك للأغراض التالية الغرض من جمع المعلومات واستخدامها
</p>

<p>LOFT VALENTINE بإذن منك ، تستخدم
</p>
<p>• لإبلاغك عن طريق البريد الإلكتروني والرسائل القصيرة ورسائل الوسائط المتعددة والهاتف لإبلاغك بالحملات ،
</p>
    
    <p>•زوارنا لتحديد ملفات تعريف 
</p>
    <p>• تقييم الطلبات والطلبات والشكاوى
</p>



<p>يتم استخدام المعلومات الشخصية وهذه البيانات فقط للأغراض المذكورة وأثناء التحقيقات القانونية ، كما هو مطلوب بأمر من المحكمة أو الإجراءات القانونية. جمع معلومات المستخدم غير الشخصية الأخرى تجمع LOFT VALENTINE تلقائيًا وبشكل مجهول ميزات مثل كثافة المستخدم والأقسام التي تمت زيارتها والمناطق التي تم النقر عليها أثناء تنقل الزوار على موقع الويب. تُستخدم هذه البيانات فقط لتحديد معدلات المراقبة والتتبع للأقسام والمناطق المختلفة ولا ترتبط بالمعلومات الشخصية لأي 
</p>
<p>زائر. في الحالات التي تستخدم فيها تقنية إحصائية في تحديد نسبة المشاهدة والنقر لمناطق مختلفة من الموقع، وليست تطبيقًا. الهدف من هذه الصورة جعل الأقسام التي تزورها بشكل متكرر في سهولة الوصول إلى الموقع

أمن المعلومات يتم اتخاذ التدابير الضرورية لضمان أمن معلوماتك الشخصية. نتعهد بحمايتها في نطاق قانون جمهورية تركيا رقم 6698 بشأن حماية البيانات الشخصية. بصفتك LOFT VALENTINE ، طالما أننا نواصل أنشطتنا التجارية ، فإن بياناتك الشخصية في نطاق هذا القانون ؛ من أجل إطلاعك على الحملات الخاصة التي أعددناها ، سنكون قادرين على تخزينها في مراكز بيانات آمنة في الداخل أو الخارج ومشاركتها مع شركاء الأعمال الذين أبرمت شركتنا معهم اتفاقية سرية. شروط استخدام الموقع يستخدم LOFT VALENTINE الموقع للإعلان عن الحملات التي ينظمها من وقت لآخر ، لإعلام الزائرين بالمواضيع والمحتويات والخدمات والأنشطة المختلفة التي يعتقد أنها قد تهم الزوار. تحتفظ LOFT VALENTINE بالحق في تغيير وتحديث محتويات الموقع من جانب واحد دون الحاجة إلى تقديم إشعار مسبق للشروط والأحكام المنشورة هنا. التغييرات في سياستنا تحتفظ LOFT VALENTINE بالحق في تغيير أو لغاء سياسة الخصوصية هذه. اتصل بنا إذا كنت تريد إبداء أي رأي حول سياسة الخصوصية هذه ، أو طرح سؤال أو الإبلاغ عن انتهاك لسياسة الخصوصية ، يمكنك استخدام رابط اتصل بنا. ستتخذ LOFT VALENTINE الخطوات اللازمة لتلبية هذه المطالب. يُعتبر كل زائر لموقع LOFT VALENTINE الإلكتروني قد قرأ وقبل كل هذه الشروط.
</p>

</div>
</div>
        <div className='kapat'><a href=''>X</a></div>
        </Popup></div>
    )
  }
}
