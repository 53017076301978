import React, { Component } from 'react';
import Navbarjs from '../component Fas/Anasayfa/navbar/Navbarjs';
import Mobililetisim from '../component Fas/İletisim/Mobililetisimsiyah'
import Hakki from '../component Fas/Hakkımızda/Hakki';
import Linkler from '../container/Linkler';
import Mobilfooter from '../component Fas/mobilfooter/Mobilfooter';
import Navbermobil from '../component Fas/Navbarmabil/Navbermobil'
import MobilLogo from '../component Fas/Anasayfa/mobillogo/MobilLogo'
export default class Hakkimizda extends Component {
  render() {
    return(
    <div className='hepsi'>
      <MobilLogo/>
      <Navbermobil/>
      <Linkler/>
        <Hakki/>
        <Navbarjs/>
        <Mobililetisim/>
        <Mobilfooter/>
    </div>
    ) ;
  }
}
