import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import { BsCameraReelsFill } from "react-icons/bs";
import Youtuube from '../Yuksekliktavanvideo';
import './style.css'

export default class Poppumpmobil extends Component {
  render() {
    return (
      <div className='buut'><Popup trigger={<button><BsCameraReelsFill/>&nbsp;&nbsp;5 Metre Tavan Yüksekliği</button>} position="right center">
      <div><Youtuube/> </div>
      <div className='kapat'><a href=''>X</a></div>
      </Popup></div>
    )
  }
}
