import React, { Component } from 'react';
import './style.css'

export default class lftval extends Component {
    constructor(props) {
    
        super(props);
        this.state = {
          MyImage: '/images/1uZqMiA.jpeg',
          MyText: 'Şehrin yeni simge yapılarından Loft Valentine İstanbul, dubleks home ofisler, ticari alanlar ve otelden oluşan mimarisiyle şehrin merkezinde yer alan karma bir yapı',
          Title:'Yüksek Tavan'
        }
      }
      hello = (tabContentID) => {
        if (tabContentID == 'button-1') {
          this.setState({ MyImage: '/images/LV GECE 2-1.jpg',Title:'Yüksek Tavan', MyText: 'adsssssssss ssssssss xsssssssssssss sssssssssssss sssssssss ssssssssssssss' });
        }
        if (tabContentID == 'button-2') {
          this.setState({ MyImage: '/images/LV GECE 2-3.jpg',Title:'Yeni Nesil İş Yaşamı', MyText: 'Şehrin yeni simge yapılarından Loft Valentine İstanbul, dubleks home ofisler, ticari alanlar ve otelden oluşan mimarisiyle şehrin merkezinde yer alan karma bir yapı' });
        }
        if (tabContentID == 'button-3') {
          this.setState({ MyImage: '/images/LV GÜNDÜZ 2-1.jpg',Title:'Çok değerli konum', MyText: 'Çok değerli konumuyla İstanbulun yeni yıldızı Loftvalentine çok yakında sizlerle. Yepyeni bir yaşam alanına tüm İstanbul a nefes aldıracak özellikleriyle Loftvalentine İstanbul.' });
        }
        if (tabContentID == 'button-4') {
          this.setState({ MyImage: '/images/LV GÜNDÜZ 2-4.jpg',Title:'Farklı Bir Anlayış', MyText: 'Şehrin yeni simge yapılarından Loft Valentine İstanbul, dubleks home ofisler, ticari alanlar ve otelden oluşan mimarisiyle şehrin merkezinde yer alan karma bir yapı' });
        }
    
    
      }
  render() {
    const { MyImage } = this.state;
    const { MyText } = this.state;
    const { Title } = this.state;
    return (
    <div className='sss'>
        
            <div className='col-lg-6 aaaa'><img src={MyImage}/></div>
            <div className='col-lg-6'>sdsdsddsdsdd</div>
        
    </div>
        );
  }
}
