import React, { Component } from 'react';
import ddd from './images/Group 209.png'
import Resimiki from './images/Yemenoglu-Gayrimenkul-Yeni-logo-3-600.png'
import './styles.css'

export default class Mobilfooter extends Component {
  render() {
    return (
    <div className='container-fluid footermob '>
        <div className='row'>
          
            <div className='col-12 foott'><img src={ddd}/></div>
        
       
        </div>
    </div>);
  }
}
