import React, { Component } from 'react';
import '../Görseller/styles.css'
import İletisimformm from './İletisimformm'
import Logo from '../Anasayfa/Logo/Logo'
import Logoresim from './images/logo.png'
import İletismsayfası from './İletisimsayfasıformu'


export default class İletisim extends Component {
  render() {
    return(
      <div >
        
         <div className='iletbox'>
         
    <p><İletismsayfası/></p>
             
             </div>
             </div>
         );
  }
}
