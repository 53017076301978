import React, { Component } from 'react';
import data from './data.json'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppumpmobil';
export default class Loftvalentinemobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/en.png',
      Title: 'You are invited to Luxe Designed Real Loft Experience!',
      Video: '5 Meters Ceiling Height',
      id:<Poppupp/>

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg',id:<Poppupp/>, Title: 'You are invited to Luxe Designed Real Loft Experience! ', MyImagetwo: '/images/en.png', Video: '5 Meters Ceiling Height' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'With its modern architectural lines, wide and spacious usage areas, luxurious and comfortable interior design details, Loft Valentine promises to offer you a life beyond your dreams.', MyImagetwo: '/images/eniki.png', Video: '' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title: 'Loft Valentine Istanbul, which has a 170-meter frontage to the Basın Ekspres Road, can be easily reached from anywhere in Istanbul, thanks to its central location, from the Metro, E5, TEM or Northern Marmara Highway.', MyImagetwo: '/images/enuc.png' });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { Video } = this.state;
    const { id } = this.state;
    return (
      <div className='container-fluid loftmobill'>
        <div className='row'>



        <div className='col-lg-6 sagloftmobil'>
            <img src={MyImage} />
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslikmobil'>
              <button onClick={() => this.buuton('button-1')}><p>LoftValentine</p></button>
              <button onClick={() => this.buuton('button-2')}><p>Concept</p></button>
              <button onClick={() => this.buuton('button-3')}><p>Location</p></button>

            </div>
            <div className='ayrintiloftmobil'><h4>{Title}</h4>
              <img src={MyImagetwo} />


            </div>
            <div className=''><center><h5 className='loftvideo'>{id}</h5></center></div>


          </div>
        </div>

      </div>

    );
  }
}