import React, { Component } from "react";
import "./Hakkiicerik.css";
import AnaResim from "./images/Ruz-inşaat Logo.png";
import AnaResimm from "./images/yemenoglu.jpg";

export default class hakkiicerik extends Component {
  render() {
    
    const baslik = "درباره ما";
    const yazi1 = "جمعیت پر رونق استانبول تقریباً 18 میلیون نفر و تقاضای بالای خریداران داخلی و خارجی، Loft Valentine را برای رشد ارزش قابل توجهی قرار می دهد. عرضه محدود زمین شهر، همراه با موقعیت منحصر به فرد پروژه و کیفیت استثنایی، Loft Valentine را به یک فرصت سرمایه گذاری هوشمند تبدیل کرده است.";
    const yazi2 = "Loft Valentine یک پروژه توسعه خارق‌العاده است که ترکیبی از تجمل، فناوری و راحتی در قلب استانبول است. این پروژه که در جاده معتبر Basin Express Road واقع شده است، دسترسی بی‌نظیر به تمام بزرگراه‌های اصلی و فرودگاه استانبول را در فاصله ۳۵ کیلومتری ارائه می‌کند.";
     const yazi3 = "این پروژه آپارتمان‌های دوبلکس 1+1 با طراحی بی‌نقص و جادار را ارائه می‌دهد که برای دفاتر خانگی، فضاهای کاری یا منازل شهری مناسب است. 90 درصد پروژه از این واحدهای همه کاره تشکیل شده است و Loft Valentine به سبک زندگی پویا حرفه ای ها و ساکنان شهر توجه دارد."

    return (
      <div>
        <div className="container">
          <div className="lofthakki">
       <h2 className="baslikdd">{baslik}</h2>
      <lu>
        <li>{yazi1}</li> 
        <li>{yazi2}</li>
        <li>{yazi3}</li>
        
       
      
      </lu>

      
</div>
<div className="container ilkk">
<lu>
<h3 className="testdenemee">نکات مهم و امکانات رفاهی</h3>
        <li>پتانسیل سرمایه گذاری بالا: افزایش جمعیت استانبول و عرضه محدود زمین، Loft Valentine را به یک سرمایه گذاری استراتژیک تبدیل کرده است.</li>
        <li>موقعیت مکانی منحصر به فرد: واقع در جاده Basın Ekspres، تنها جاده ای که تمام بزرگراه های اصلی استانبول را به هم متصل می کند و اتصال عالی را ارائه می دهد.</li>
        <li>فضاهای نشیمن همه کاره: آپارتمان های شیک 1+1 دوبلکس مناسب برای کاربری های مختلف، طراحی شده برای رفع نیازهای حرفه ای های مدرن</li>
        <li>امکانات رفاهی لوکس: شامل مرکز تناسب اندام پیشرفته، استودیو یوگا، بام سبز، باغ اجتماعی، و خدمات دربان 24/7 پنج ستاره.</li>
<li>
می توانید از طریق آدرس گوگل درایو زیر به فایل ارائه دسترسی داشته باشید.</li>
<p classNam="adenemers"><a  href="https://drive.google.com/drive/folders/1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV"><p className="peer">https://drive.google.com/drive/folders /1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV</p></a></p>
      
      
</lu>
</div>
      </div>
      </div>
    );
  }
}
