import React, { Component } from 'react';
import Mobilfooter from '../component/mobilfooter/Mobilfooter';
import Katplanı from '../component/Katplanı/Katplanı';
import Navbarjs from '../component/Anasayfa/navbar/Navbarjs'
import Navbermobil from '../component/Navbarmabil/Navbermobil';
import MobilLogo from '../component/Anasayfa/mobillogo/MobilLogo'
import Mobililetisim from '../component/İletisim/Mobililetisimsiyah'
import Katplanimobil from '../component/Katplanı/Katplanimobil';
import Linkler from '../container/Linkler';
export default class Kat extends Component {
  render() {
    return (
      <div className='hepsi'>
<MobilLogo/>
<Linkler/>
      <Navbermobil/>
      <Katplanı/>
      
      <Katplanimobil/>
      <Mobililetisim/>
      <Navbarjs/>
      <Mobilfooter/>
      

  </div>
    )
  ;
  }
}
