import React, { Component } from 'react'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppupp';
import Resim from './images/Group 253.png'
export default class Loftvalentineone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'شما به تجربه Loft Real Designed Luxe دعوت شده اید!',
      Video: 'ارتفاع سقف 5 متر',
      id:<Poppupp/>,
      Altyazi:"Loft Valentine که برای برآورده کردن الزامات درجه یک طراحی شده است، به مهمانان خود نوید یک زندگی باشکوه در محبوب ترین مکان استانبول را می دهد.",
      

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg', id:<Poppupp/>, Title: 'شما به تجربه Loft Real Designed Luxe دعوت شده اید!', MyImagetwo: '/images/loftvalen.png', Video: '5 Metre Tavan Yüksekliği', Altyazi:"Loft Valentine که برای برآورده کردن الزامات درجه یک طراحی شده است، به مهمانان خود نوید یک زندگی باشکوه در محبوب ترین مکان استانبول را می دهد.",Altyazia:"", Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Loft Valentine با خطوط معماری مدرن، مناطق کاربری وسیع و جادار، جزئیات طراحی داخلی مجلل و راحت، قول می دهد زندگی فراتر از رویاهای شما را به شما ارائه دهد.', MyImagetwo: '/images/Konsopt.png', Video: '',Altyazi:"Loft Valentine ساختمانی است که استانداردهای زندگی را بالا می برد، آسایش مهمانان خود را با فناوری های ساختمان هوشمند موجود به حداکثر می رساند و ثابت می کند که زندگی با انرژی کارآمد امکان پذیر است",Altyazia:"" ,Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title:'Loft Valentine Istanbul، که دارای 170 متر نما به جاده Basın Ekspres است، به لطف موقعیت مرکزی آن، از مترو، E5، TEM یا بزرگراه مرمره شمالی، به راحتی از هر نقطه استانبول قابل دسترسی است.', MyImagetwo: '/images/lokasyon.png',Altyazi:"", Altyazia:"متر 400 مترو ", Altyazibir:"کیلومتر 36 استانبول فرودگاه", Altyaziiki:"کیلومتر 5 آتاتورک فرودگاه", Altyaziuc:"کیلومتر 4 بزرگراه E5",Altyazidort:"بزرگراه مرمره شمالی 7 کیلومتر",Altyazibes:"کیلومتر 7 شمالی مرمره بزرگراه",Altyaziyedi:"6.5 ساحل تا کیلومتر",Altyazisekiz:"کیلومتر 7 شمالی مرمره بزرگراه",Altyazidokuz:"کیلومتر 8 استانبول خرید مرکز ",Altyazion:"Starcity AVM 800 متر",Altyazionbir:"212 AVM 5 کیلومتر" , Altyazioniki:"کیلومتر 25 تکسیم" ,Altyazioniki:"کیلومتر 28 بسفر ",Altyazionuc:"است تقاطع 2 بین BASIN.و EXPRESS جاده به کامل نمای دارای " ,Altyaziondort:""    });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { id } = this.state;
    const {Altyazi}=this.state;
    const {Altyazia}=this.state;
    const {Altyazibir}=this.state;
    const {Altyaziiki}=this.state;
    const {Altyaziuc}=this.state;
    const {Altyazidort}=this.state;
    const {Altyazibes}=this.state;
    const {Altyazialtı}=this.state;
    const {Altyaziyedi}=this.state;
    const {Altyazisekiz}=this.state;
    const {Altyazidokuz}=this.state;
    const {Altyazion}=this.state;
    const {Altyazionbir}=this.state;
    const {Altyazioniki}=this.state;
    const {Altyazionuc}=this.state;
    const {Altyaziondort}=this.state;
    return (
      <div className='container-fluid tumsayfaloft'>
        <div className='row'>
          <div className='col-lg-1 loftcolbir'>
            <img  src={Resim}/>
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslik'>
              <button onClick={() => this.buuton('button-1')}><h5>Loft Valentine</h5></button>
              <button onClick={() => this.buuton('button-2')}><h5>مفهوم</h5></button>
              <button onClick={() => this.buuton('button-3')}><h5>محلn</h5></button>

            </div>
            <div className='ayrintiloft'><h4>{Title}</h4><br/>
            <p>{Altyazi}</p>
            <div className='wesd'>
            <h5>{Altyazia}</h5>
              <h5>{Altyazibir}</h5>
              <h5>{Altyaziiki}</h5>
              <h5>{Altyaziuc}</h5>
              <h5>{Altyazidort}</h5>
              <h5>{Altyazibes}</h5>
              <h5>{Altyazialtı}</h5>
              <h5>{Altyaziyedi}</h5>
              <h5>{Altyazisekiz}</h5>
              <h5>{Altyazidokuz}</h5>
              <h5>{Altyazion}</h5>
              <h5>{Altyazionbir}</h5>
              <h5>{Altyazioniki}</h5>
              <h5>{Altyazionuc}</h5>
              <h5>{Altyaziondort}</h5>
              
</div>

            </div>
            <div className=''><center><h5  className='loftvideo'>{id}</h5></center></div>


          </div>
          <div className='col-lg-5 sagloft'>
            <img src={MyImage} />
          </div>
        </div>
      </div>
    )
  }
}
