import React, { Component } from 'react';
import Navbarjs from '../componentRus/Anasayfa/navbar/Navbarjs';
import Gorsel from '../componentRus/Gorsel2/Gorsell';
import Mobilfooter from '../componentRus/mobilfooter/Mobilfooter';
import Navbermobil from '../componentRus/Navbarmabil/Navbermobil';
import MobilLogo from '../componentRus/Anasayfa/mobillogo/MobilLogo'
import Mobililetisim from '../componentRus/İletisim/Mobililetisimsiyah'
import Linkler from '../container/Linkler';
export default class Görsel extends Component {
  render() {
    return(
    <div className='hepsi'>
      <MobilLogo/>
      <Navbermobil/>
      <Linkler/>
        <Gorsel/>
        <Navbarjs/>
        <Mobililetisim/>
        <Mobilfooter/>
        
    </div>
    ) ;
  }
}
