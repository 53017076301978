import React, { Component } from "react";
import "./Hakkiicerik.css";
import AnaResim from "./images/Ruz-inşaat Logo.png";
import AnaResimm from "./images/yemenoglu.jpg";

export default class hakkiicerik extends Component {
  render() {
    
    const baslik = "Hakkımızda";
    const yazi1 = "İstanbul'un neredeyse 18 milyonluk gelişen nüfusu ve yerli ve yabancı alıcıların yüksek talebi, Loft Valentine'i önemli bir değer artışı için konumlandırıyor. Şehrin sınırlı arazi arzı, projenin benzersiz konumu ve olağanüstü  kalitesi ile birleştiğinde, Loft Valentine'i akıllı bir yatırım fırsatı haline getiriyor.";
    const yazi2 = "Loft Valentine, İstanbul'un kalbinde lüks, teknoloji ve konforu bir araya getiren olağanüstü bir gelişim projesidir. Prestijli Basin Ekspres Yolu üzerinde yer alan proje, tüm ana otoyollara ve sadece 35 km uzaklıktaki İstanbul Havalimanı'na  rakipsiz erişim sunuyor";
     const yazi3 = "Proje, ev ofisler, çalışma alanları veya kentsel konutlar için mükemmel olan geniş, kusursuz tasarlanmış 1+1 dubleks daireler sunmaktadır. Projenin %90’ı bu çok yönlü birimlerden  oluşmaktadır ve Loft Valentine, profesyonellerin ve şehir sakinlerinin dinamik yaşam tarzlarına hitap etmektedir."

    return (
      <div>
        <div className="container">
          <div className="lofthakki">
       <h2 className="baslikdd">{baslik}</h2>
      <lu>
        <li>{yazi1}</li> 
        <li>{yazi2}</li>
        <li>{yazi3}</li>
        
       
      
      </lu>

      
</div>
<div className="container ilkk">
<lu>
<h3 className="testdenemee">Öne Çıkan Özellikler ve Olanaklar</h3>
        <li>Yüksek Yatırım Potansiyeli: İstanbul'un artan nüfusu ve sınırlı
arazi arzı, Loft Valentine'i stratejik bir yatırım haline getiriyor.</li>
        <li>Eşsiz Konum: İstanbul'daki tüm ana otoyolları birbirine
bağlayan tek yol olan Basın Ekspres Yolu üzerinde yer almakta ve
mükemmel bağlantı sağlamaktadır</li>
        <li>Çok Yönlü Yaşam Alanları: Modern profesyonellerin
ihtiyaçlarını karşılamak için tasarlanmış, çeşitli kullanımlara uygun
şık 1+1 dubleks
daireler</li>
        <li>Lüks Olanaklar: En son teknolojiye sahip fitness merkezi, yoga
stüdyosu, yeşil çatı, topluluk bahçesi ve beş yıldızlı, 7/24
concierge hizmeti içerir.</li>
<li>Sunum dosyasına aşağıdaki Google drive adresi üzerinden
ulaşabilirsiniz. </li>
<p classNam="adenemers"><a  href="https://drive.google.com/drive/folders/1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV"><p className="peer">https://drive.google.com/drive/folders /1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV</p></a></p>
      
      
</lu>
</div>
      </div>
      </div>
    );
  }
}
