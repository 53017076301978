import React, { Component } from 'react'
import Popup from 'reactjs-popup';

import './style.css'
export default class Popupgizlilik extends Component {
  render() {
    return (
        <div className=''><Popup trigger={<b>توافقنامه محرمانه</b>} position="right center">
        <div className='gizlilikyazi'>
          <div className='scrol'>
           <h5>توافقنامه محرمانه</h5><p>
           LOFT VALENTINE از حریم خصوصی شما محافظت می کند.
این سیاست حفظ حریم خصوصی انواع اطلاعات جمع آوری شده توسط LOFT VALENTINE در این وب سایت و نحوه محافظت و ذخیره آن را مشخص می کند.
LOFT VALENTINE اطلاعات شخصی هیچ یک از کاربران وب سایت LOFT VALENTINE را جمع آوری نمی کند مگر اینکه به طور داوطلبانه توسط بازدید کننده ارائه شده باشد.
به عنوان یک قاعده، داده های شخصی (نام، آدرس ایمیل، شماره تلفن) که توسط بازدیدکنندگان ما در چارچوب این سیاست حفظ حریم خصوصی به سایت ما ارسال می شود، با اشخاص ثالث به اشتراک گذاشته نمی شود، فروخته نمی شود، اجاره نمی شود یا به روش دیگری استفاده می شود.
</p><p>
مجموعه اطلاعات شخصی
استفاده از وب سایت LOFT VALENTINE و ارائه داوطلبانه اطلاعات شخصی به این معنی است که سیاست حفظ حریم خصوصی و شرایط فعلی اعلام شده در این سایت پذیرفته می شود.</p>
<p>هدف و استفاده از مجموعه اطلاعات</p>

<p>با اجازه شما، LOFT VALENTINE از اطلاعات شما برای اهداف زیر استفاده می کند.</p>
<p>• برای اطلاع شما از طریق ایمیل، پیامک، MMS و تلفن، برای اطلاع رسانی کمپین ها،</p>
    
    <p>• برای تعیین نمایه های بازدیدکننده ما،
</p>
    <p>• درخواست ها، درخواست ها و شکایات را ارزیابی کنید</p>



<p>اطلاعات شخصی و این داده ها فقط برای اهداف اعلام شده و در طول تحقیقات قانونی، طبق دستور دادگاه یا مراحل قانونی مورد استفاده قرار می گیرند.
مجموعه سایر اطلاعات غیر شخصی کاربر
LOFT VALENTINE به طور خودکار و ناشناس ویژگی هایی مانند تراکم کاربر، بخش های بازدید شده، مناطق کلیک شده را در طول ناوبری بازدیدکنندگان در وب سایت جمع آوری می کند. این داده‌ها فقط برای تعیین نرخ نظارت و ردیابی بخش‌ها و مناطق مختلف استفاده می‌شوند و با اطلاعات شخصی هیچ بازدیدکننده مرتبط نیستند. در مواردی که از فناوری موسوم به «کوکی» استفاده می‌شود که تعیین می‌کند کجا و چگونه از صفحات بازدید می‌شود، به‌عنوان داده‌های آماری در تعیین میزان بازدید و کلیک در مناطق مختلف سایت نیز جمع‌آوری می‌شود و یک برنامه شخصی‌سازی نشده است. هدف این فناوری این است که از اولین بازدید از سایت، محتوای بخش‌هایی را که کاربران به دفعات بیشتر از آن بازدید می‌کنند در دسترس کاربر قرار دهد.</p>
<p>امنیت اطلاعات
اقدامات لازم برای اطمینان از امنیت اطلاعات شخصی شما انجام می شود. ما متعهد می شویم که از آن در محدوده قانون شماره 6698 جمهوری ترکیه در مورد حفاظت از داده های شخصی محافظت کنیم. تا زمانی که LOFT VALENTINE به فعالیت های تجاری خود ادامه می دهیم، اطلاعات شخصی شما در محدوده این قانون است. برای اینکه شما را از کمپین‌های ویژه‌ای که آماده کرده‌ایم مطلع کنیم، می‌توانیم آنها را در مراکز داده امن در داخل یا خارج از کشور ذخیره کنیم و با شرکای تجاری که شرکت ما با آنها توافقنامه محرمانه دارد، به اشتراک بگذاریم.
شرایط استفاده از سایت
LOFT VALENTINE از وب‌سایت برای اعلام کمپین‌هایی که هر از گاهی برگزار می‌کند استفاده می‌کند تا بازدیدکنندگان را در مورد موضوعات، مطالب و خدمات و فعالیت‌های مختلفی که فکر می‌کند ممکن است مورد علاقه بازدیدکنندگان باشد مطلع کند. LOFT VALENTINE این حق را برای خود محفوظ می دارد که به طور یکجانبه محتویات وب سایت را بدون اطلاع قبلی نسبت به شرایط و ضوابط منتشر شده در اینجا تغییر داده و به روز کند.

تغییرات در خط مشی ما
LOFT VALENTINE حق تغییر یا لغو این سیاست حفظ حریم خصوصی را برای خود محفوظ می دارد.

با ما تماس بگیرید
اگر مایل به ابراز نظر، طرح سوال یا گزارش نقض این سیاست حفظ حریم خصوصی هستید، می توانید از پیوند تماس با ما استفاده کنید. LOFT VALENTINE اقدامات لازم را برای برآورده کردن این خواسته ها انجام خواهد داد.
هر بازدید کننده از وب سایت LOFT VALENTINE تلقی می شود که همه این شرایط را خوانده و پذیرفته است.</p>

</div>
</div>
        <div className='kapat'><a href=''>X</a></div>
        </Popup></div>
    )
  }
}
