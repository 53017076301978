import React from 'react'
import Popup from 'reactjs-popup';
import './style.css'
import { BsCameraReelsFill } from "react-icons/bs";
import Youtubereklam from './Youtubereklam';
export default function PopupReklam() {
  return (
    <div className='buut'><Popup trigger={<p className='sagup'><BsCameraReelsFill/>&nbsp;&nbsp;advertising film</p>} position="right center">
      <div><Youtubereklam/> </div>
      <div className='kapat'><a href=''>Kapat</a></div>
      </Popup></div>
  )
}

