import React, { Component } from 'react';
import data from './data.json'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppumpmobil';
export default class Loftvalentinemobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'Lüx Dizayn Edilmiş Gerçek Loft Yaşantısına davetlisiniz!',
      Video: '5 Metre Tavan Yüksekliği',
      id:<Poppupp/>

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg',id:<Poppupp/>, Title: 'Lüx Dizayn Edilmiş Gerçek Loft Yaşantısına davetlisiniz! ', MyImagetwo: '/images/loftvalen.png', Video: '5 Metre Tavan Yüksekliği' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Modern mimari çizgileri, geniş ve ferah kullanım alanları,lüks ve aynı zamanda konforlu iç tasarım detayları ile Loft Valentine,size hayallerinizin ötesinde bir  yaşam sunmayı vaat ediyor.', MyImagetwo: '/images/Konsopt.png', Video: '' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title: ' Basın Ekspres Yoluna 170 metre cephesi olan Loft Valentine İstanbul, merkezi konumuyla, Metro, E5, TEM veya Kuzey Marmara Otoyolundan, dolayısıyla İstanbul’un her yerinden kolayca ulaşılabiliyor.', MyImagetwo: '/images/lokasyon.png' });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { Video } = this.state;
    const { id } = this.state;
    return (
      <div className='container-fluid loftmobill'>
        <div className='row'>



        <div className='col-lg-6 sagloftmobil'>
            <img src={MyImage} />
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslikmobil'>
              <button onClick={() => this.buuton('button-1')}><p>LoftValentine</p></button>
              <button onClick={() => this.buuton('button-2')}><p>Konsept</p></button>
              <button onClick={() => this.buuton('button-3')}><p>Lokasyon</p></button>

            </div>
            <div className='ayrintiloftmobil'><h4>{Title}</h4>
              <img src={MyImagetwo} />


            </div>
            <div className=''><center><h5 className='loftvideo'>{id}</h5></center></div>


          </div>
        </div>

      </div>

    );
  }
}