import React, { Component } from 'react'
import Popup from 'reactjs-popup';

import './style.css'
export default class Popupgizlilik extends Component {
  render() {
    return (
        <div className=''><Popup trigger={<b>соглашение о конфиденциальности</b>} position="right center">
        <div className='gizlilikyazi'>
          <div className='scrol'>
           <h5>Gizlilik Sözleşmesi</h5><p>
           Компания LOFT VALENTINE заботится о защите вашей личной конфиденциальности. В настоящей
политике конфиденциальности описываются типы информации, собираемой LOFT VALENTINE на
этом веб-сайте, а также способы ее защиты и хранения. LOFT VALENTINE не собирает личную
информацию о пользователе веб-сайта LOFT VALENTINE, если она не предоставлена посетителем
добровольно. В соответствии с настоящей политикой конфиденциальности, как правило,
персональные данные (ваше имя, адрес электронной почты, номера телефонов), представленные
посетителями нашего сайта, не передаются, не продаются, не сдаются в аренду и иным образом
не используются третьими лицами.</p><p>
Сбор личной информации об использования веб-сайта LOFT VALENTINE, добровольное
предоставление приватной информации означает согласие с действующей политикой
конфиденциальности и условиями, изложенными на этом сайте.</p><p>Цель сбора информации и способ ее использования</p>

<p>С вашего разрешения LOFT VALENTINE использует вашу информацию для следующих целей;</p>
<p>• Информировать вас по электронной почте, SMS, MMS и телефону, информировать о компаниях,</p>
    
    <p>• Определение профилей наших посетителей,</p>
    <p>• Оценивать запросы, претензии и жалобы</p>



<p>Личная информация и эти данные используются только в указанных целях и в ходе юридических
расследований, если это требуется по решению суда или в соответствии с юридическими
процедурами. Сбор другой неличной пользовательской информации Во время обращения
посетителей на веб-сайте LOFT VALENTINE автоматически и анонимно собирает такие функции, как
плотность пользователей, посещенные разделы, области, на которые были нажаты. Эти данные
используются только для определения скорости отслеживания и отслеживания разделов,
различных областей и не связаны с личной информацией какого-либо посетителя. В тех случаях,
когда используется технология, называемая "cookie" (cookie), которая определяет, где проходят
страницы или каким образом они посещаются, отслеживание различных областей сайта
собирается в виде статистических данных для определения скорости кликов и не является
индивидуальным приложением. Эта технология предназначена для того, чтобы сделать контент
разделов, которые пользователи посещают чаще, более доступным для пользователя с момента
его первого посещения сайта.</p>
<p>Информационная безопасность принимает необходимые меры для обеспечения безопасности
вашей личной информации. Мы обязуемся защищать его в соответствии с Законом о защите
персональных данных Турецкой Республики № 6698 Пока мы продолжаем нашу коммерческую
деятельность в качестве LOFT VALENTINE, в соответствии с настоящим законом мы сможем хранить
ваши персональные данные в защищенных центрах обработки данных внутри страны или за
рубежом и делиться ими с деловыми партнерами, с которыми наша компания заключила
соглашение о конфиденциальности, чтобы информировать вас о специальных кампаниях, которые
мы готовим. Условия использования сайта LOFT VALENTINE время от времени использует веб-сайт
для объявления проводимых им кампаний, тем, которые, по его мнению, могут представлять
интерес для посетителей, а также для информирования посетителей о различных услугах и
работах. Компания LOFT VALENTINE имеет право в одностороннем порядке изменять и обновлять
содержимое веб-сайта в соответствии с условиями, которые она публикует здесь, без
предварительного уведомления. Изменения в нашей Политике Компания LOFT VALENTINE
оставляет за собой право изменять или отменять настоящую Политику конфиденциальности.
Свяжитесь с нами Если вы хотите высказать какие-либо замечания, задать вопросы или сообщить
о нарушении настоящей Политики конфиденциальности, вы можете воспользоваться ссылкой для
связи с нами. Компания LOFT VALENTINE предпримет необходимые шаги для удовлетворения этих
требований. Каждый посетитель веб-сайта LOFT VALENTINE считается прочитавшим и принявшим
все эти условия.</p>

</div>
</div>
        <div className='kapat'><a href=''>X</a></div>
        </Popup></div>
    )
  }
}
