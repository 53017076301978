import React, { Component } from "react";
import "./Hakkiicerik.css";
import AnaResim from "./images/Ruz-inşaat Logo.png";
import AnaResimm from "./images/yemenoglu.jpg";

export default class hakkiicerik extends Component {
  render() {
    
    const baslik = "О нас";
    const yazi1 = "Процветающее население Стамбула, насчитывающее почти 18 миллионов человек, и высокий спрос со стороны отечественных и иностранных покупателей позволяют Loft Valentine значительно вырасти в цене. Ограниченное количество земли в городе в сочетании с уникальным расположением проекта и исключительным качеством делают Loft Valentine выгодной инвестиционной возможностью.";
    const yazi2 = "Loft Valentine — это необычный девелоперский проект, сочетающий в себе роскошь, технологии и комфорт в самом сердце Стамбула. Расположенный на престижной улице Basin Express Road, проект предлагает непревзойденный доступ ко всем основным автомагистралям и аэропорту Стамбула, расположенному всего в 35 км.";
     const yazi3 = "Проект предлагает просторные, безупречно спроектированные дуплексы 1+1, идеально подходящие для домашних офисов, рабочих помещений или городских резиденций. 90% проекта состоит из этих универсальных объектов, и Loft Valentine обращается к динамичному образу жизни профессионалов и горожан.."

    return (
      <div>
        <div className="container">
          <div className="lofthakki">
       <h2 className="baslikdd">{baslik}</h2>
      <lu>
        <li>{yazi1}</li> 
        <li>{yazi2}</li>
        <li>{yazi3}</li>
        
       
      
      </lu>

      
</div>
<div className="container ilkk">
<lu>
<h3 className="testdenemee">Основные моменты и удобства</h3>
        <li>Высокий инвестиционный потенциал: растущее население Стамбула и ограниченное предложение земли делают Loft Valentine стратегической инвестицией.</li>
        <li>Уникальное расположение: расположен на улице Басин Экспрес, единственной дороге, соединяющей все основные автомагистрали Стамбула, обеспечивающей отличное транспортное сообщение.</li>
        <li>Универсальные жилые помещения: стильные двухуровневые апартаменты 1+1, подходящие для различного использования и спроектированные с учетом потребностей современных профессионалов.</li>
        <li>Роскошные удобства: включает в себя современный фитнес-центр, студию йоги, зеленую крышу, общественный сад и пятизвездочную круглосуточную службу консьержа.</li>
<li>Вы можете получить доступ к файлу презентации по адресу Google Диска, указанному ниже.</li>
<p classNam="adenemers"><a  href="https://drive.google.com/drive/folders/1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV"><p className="peer">https://drive.google.com/drive/folders /1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV</p></a></p>
      
      
</lu>
</div>
      </div>
      </div>
    );
  }
}
