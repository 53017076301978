import React, { Component } from 'react';
import Resim from './images/LV LOGO PNG.png';
import '../Görseller/styles.css';
import İletisimformm from './Mobilformsiyah'

export default class Mobililetisimsiyah extends Component {
  render() {
    return (
    <div> 
        <div className='container-fluid'>
          
            <div className='col-md-12 col-12 mobilanasayfa'>
             
              
           
           
               <br></br>
               <div className='telefon'><center><h3><b>0532 694 00 79</b></h3></center></div>
               
            </div>
            <div className='col-md-12 col-12 iletisimyazi'>
           
                <İletisimformm/>
            </div>
        </div>
    </div>);
  }
}
