import React, { Component } from 'react';
import Loftvalentine from '../component En/Loftvalentine/Loftvalentineone';
import Logo from '../component En/Anasayfa/Logo/Logo';
import Navbarjs from '../component En/Anasayfa/navbar/Navbarjs';
import Navbermobil from '../component En/Navbarmabil/Navbermobil';
import Mobilfooter from '../component En/mobilfooter/Mobilfooter';
import Mobililetisim from '../component/İletisim/Mobililetisimsiyah';
import MobilLogo from '../component En/Anasayfa/mobillogo/MobilLogo'
import Loftvalentinemobil from '../component En/Loftvalentine/Loftvalentinemobil';
import Linkler from '../container/Linkler';

export default class Loftvalentinepage extends Component {
  render() {
    return (
    <div className='hepsi'>
      <Navbermobil/>
      <MobilLogo/>
        <Logo/>
        <Linkler/>
        <Loftvalentine/>
        <Loftvalentinemobil/>
        <Navbarjs/>
        <Mobililetisim/>
        <Mobilfooter/>
        

        
    </div>);
  }
}
