import React, { Component } from 'react';
import data from './data.json'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppumpmobil';
export default class Loftvalentinemobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/rusyaziii.png',
      Title: 'Приглашаем вас в Luxe Designed Real Loft Experience!',
      Video: '5 метров высота потолка',
      id:<Poppupp/>

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg',id:<Poppupp/>, Title: 'Приглашаем вас в Luxe Designed Real Loft Experience! ', MyImagetwo: '/images/rusyaziii.png', Video: '5 метров высота потолка' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Проект Loft Valentine Marriott поднимает планку роскошной жизни благодаря инновационному использованию интеллектуальных строительных технологий. Это впечатляющее здание может  похвастаться 407 роскошными домашними офисами в двухуровневых люксах, 220 роскошными гостиничными номерами Marriott и множеством роскошных коммерческих помещений.', MyImagetwo: '/images/rusyazii.png', Video: '' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title: ' Концепция Loft Valentine Istanbul расположен в 170 метрах от дороги Basin Express находящийся вцентре Стамбула, до него легко добраться на метро,по автомагистрали E5, Kuzey Marmara Otoyolu, поэтому это облегчает путь до ваших апартаментов.', MyImagetwo: '/images/rusyazi.png' });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { Video } = this.state;
    const { id } = this.state;
    return (
      <div className='container-fluid loftmobill'>
        <div className='row'>



        <div className='col-lg-6 sagloftmobil'>
            <img src={MyImage} />
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslikmobil'>
              <button onClick={() => this.buuton('button-1')}><p>лофт валентинка</p></button>
              <button onClick={() => this.buuton('button-2')}><p>концепция</p></button>
              <button onClick={() => this.buuton('button-3')}><p>среда</p></button>

            </div>
            <div className='ayrintiloftmobil'><h4>{Title}</h4>
              <img src={MyImagetwo} />


            </div>
            <div className=''><center><h5 className='loftvideo'>{id}</h5></center></div>


          </div>
        </div>

      </div>

    );
  }
}