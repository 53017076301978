import React, { Component } from 'react';
import data from './data.json'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppumpmobil';
export default class Loftvalentinemobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/arabic.png',
      Title: 'تصميم Loft Valentine Marriott لتلبية متطلبات الدرجة الأولى ، ويعد ضيوفه بحياة رائعة في أكثر المواقع شهرة في اسطنبول.',
      Video: '5 أمتار ارتفاع السقف',
      id:<Poppupp/>

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg',id:<Poppupp/>, Title: 'أنت مدعو إلى تجربة Luxe Designed Real Loft!', MyImagetwo: '/images/arabic.png', Video: '5 أمتار ارتفاع السقف' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'يرفع مشروع  لوفت فالنتاين مستوى المعيشة الفاخرة من خلال الاستخدام المبتكر لتقنيات المباني الذكية. يضم هذا المبنى المثير للإعجاب  407 مكتبًا منزليًا فاخرًا للأجنحة المزدوجة و 220 غرفة فندقية فاخرة من ماريوت والعديد من المساحات التجارية الفاخرة. لا يعطي مشروع الأولوية لراحة النزيل فحسب ، بل يعرض ', MyImagetwo: '/images/arabicc.png', Video: '' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title: ' بسهولة ،  مترًا 170 طوله يبلغ الذي Basın لطريق Loft Valentine Istanbul إلى الوصول يمكن Ekspres عن طريق ، إسطنبول في مكان ي أ من ، لمركزيا موقعه بفضل ، من المترو أو E5 أو TEM .طريق مرمرة شمال السريع ', MyImagetwo: '/images/arabiccc.png' });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { Video } = this.state;
    const { id } = this.state;
    return (
      <div className='container-fluid loftmobill'>
        <div className='row'>



        <div className='col-lg-6 sagloftmobil'>
            <img src={MyImage} />
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslikmobil'>
              <button onClick={() => this.buuton('button-1')}><p>LoftValentine</p></button>
              <button onClick={() => this.buuton('button-2')}><p>مفهوم</p></button>
              <button onClick={() => this.buuton('button-3')}><p>موقع</p></button>

            </div>
            <div className='ayrintiloftmobil'><h4>{Title}</h4>
              <img src={MyImagetwo} />


            </div>
            <div className=''><center><h5 className='loftvideo'>{id}</h5></center></div>


          </div>
        </div>

      </div>

    );
  }
}