import React, { Component } from 'react'
import Popup from 'reactjs-popup';

import './style.css'
export default class Popupgizlilik extends Component {
  render() {
    return (
        <div className=''><Popup trigger={<b>Confidentiality Agreement</b>} position="right center">
        <div className='gizlilikyazi'>
          <div className='scrol'>
           <h5>Confidentiality Agreement</h5><p>
           LOFT VALENTINE takes care to protect your personal privacy. This privacy policy outlines the types of information collected by LOFT VALENTINE on this website and how it is protected and stored. LOFT VALENTINE does not collect personal information about any LOFT VALENTINE website user unless it is voluntarily provided by the visitor. As a rule, personal data (name, e-mail address, telephone numbers) submitted to our site by our visitors within the scope of this privacy policy are not shared with third parties, sold, rented or used in any other way.
Collection of Personal Information Using the LOFT VALENTINE website and voluntarily providing personal information means that the current privacy policy and conditions announced on this site are accepted.
Purpose and Usage of Information Collection</p>

<p>With your permission, LOFT VALENTINE uses your information for the following purposes</p>
<p>•To inform you by e-mail, SMS, MMS and telephone, to inform you about the campaigns,</p>
    
    <p>• To determine our visitor profiles,</p>
    <p>• Evaluate requests, requests and complaints</p>



<p>Personal information and this data are used only for the stated purposes and during legal investigations, as required by court order or legal procedures. Collection of Other Non-Personal User Information LOFT VALENTINE automatically and anonymously collects features such as user density, sections visited, areas clicked during the visitors' navigation on the website. These data are only used to determine the monitoring and tracking rates of sections and different areas and are not associated with any visitor's personal information. In cases where the technology called "cookie" is used, which determines where or how the pages are visited, it is also collected as statistical data in determining the viewing and clicking rates of different areas of the site, and is not a personalized application. The aim of this technology is to make the content of the sections that users visit more frequently more easily accessible for the user from the first visit to the site.</p>
<p>Information Security Necessary measures are taken to ensure the security of your personal information. We undertake to protect it within the scope of the Republic of Turkey Law No. 6698 on the Protection of Personal Data. As LOFT VALENTINE, as long as we continue our commercial activities, your personal data within the scope of this law; To inform you about the special campaigns we have prepared</p>

</div>
</div>
        <div className='kapat'><a href=''>X</a></div>
        </Popup></div>
    )
  }
}
