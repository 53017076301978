import React, { Component } from 'react';
import İletisim from '../componentArabic/İletisim/İletisim'
import Navbermobil from '../componentArabic/Navbarmabil/Navbermobil';
import Navbarjs from '../componentArabic/Anasayfa/navbar/Navbarjs';
import MobilLogo from '../componentArabic/Anasayfa/mobillogo/MobilLogo'
import Logo from '../componentArabic/Anasayfa/Logo/Logo'
import Mobililetisim from '../componentArabic/İletisim/Mobilhomeiletisim'
import Mobilfooter from '../componentArabic/mobilfooter/Mobilfooter'
import Resim from '../componentArabic/İletisim/images/mobil-iletisim.jpg'

export default class İletisimpage extends Component {
  render() {
    return (
    <div className='ilet'>
    
      <Logo/>
      <MobilLogo/>
      
<Navbermobil/>
<div className=''>
         <div className='iletisimresim'>
           <img src={Resim}/>
         </div>
         </div>
<İletisim/>
<Navbarjs/>
<Mobililetisim/>
                <Mobilfooter/>
    </div>);
  }
}
