import React, { Component } from 'react'
import './style.css'
import Logo from '../Anasayfa/Logo/Logo';
import Gallery from 'react-grid-gallery';
import Okresim from './images/Group 253.png'



export default class Katplanı extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/TİP_AD.png'
    }
  }
  hello = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/TİP_AD.png', MyText: '1' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/TİP1-BB.png', MyText: '1' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/Tip-1CD.png', MyText: '1' });
    }
    if (tabContentID == 'button-4') {
      this.setState({ MyImage: '/images/TİP-2 AGDpng.png', MyText: '1' });
    }
    if (tabContentID == 'button-5') {
      this.setState({ MyImage: '/images/TİP2-AKD.png', MyText: '1' });
    }
    if (tabContentID == 'button-6') {
      this.setState({ MyImage: '/images/TİP-2BGB.png', MyText: '1' });
    }
    if (tabContentID == 'button-7') {
      this.setState({ MyImage: '/images/TİP2-BKB.png', MyText: '1' });
    }

  }
  render() {
    const IMAGES =
      [{
        src: this.state.MyImage,
        thumbnail: this.state.MyImage,
        isSelected: false,
        caption: "loftvalentine"
      },]
    const { MyImage } = this.state;
    return (
      <div className='body'>
        <Logo />
        <div className='container-fluid dd'>
          <div className='row'>
            <div className='col-lg-1'>
            <img className='katresimsayfa' src={Okresim}/>
            </div>
          
            <div className='col-lg-3 daierler'>

              <ul>
                <li><button onClick={() => this.hello('button-1')}><h5>TİP 1 - AD</h5></button></li>
                <li><button onClick={() => this.hello('button-2')}><h5>TİP 1 - BB</h5></button></li>
                <li><button onClick={() => this.hello('button-3')}><h5>TİP 1 - CD</h5></button></li>
                <li> <button onClick={() => this.hello('button-4')}><h5>TİP 1 - AGD</h5></button></li>
                <li> <button onClick={() => this.hello('button-5')}><h5>TİP 2 - AKD</h5></button></li>
                <li><button onClick={() => this.hello('button-6')}><h5>TİP 1 - BGB</h5></button></li>
                <li> <button onClick={() => this.hello('button-7')}><h5>TİP 1 - BKB</h5></button></li>
              </ul>

            </div>


            <div className='col-lg-7 resim'><Gallery images={IMAGES} /></div>

          </div>
        </div>
      </div>

    )
  }
}


