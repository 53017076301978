

import React, { Component } from 'react'
import Giftavan from './poppup/images/5metre.gif'

export default class Yuksekliktavanvideo extends Component {
  render() {
    return (
      <div className='bestavanmobiil'>
        <img src={Giftavan}/>
      </div>
    )
  }
}
