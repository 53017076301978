import React from 'react'
import '../Görseller/styles.css';
import İletisimformm from './Mobilformsiyah'

export default function Mobilhomeiletisim() {
  return (
    <div>
        <div className='container-fluid'>
          
          <div className='col-md-12 col-12 mobilanasayfa'>
           
            
          <ul>
               <li><h1>Лофт Валентайн,</h1> </li>
               <li><h1>Роскошный дизайн</h1></li>
               <li><h1>Настоящий лофт!</h1></li>
             </ul>
            
             <br></br>
             <div className='telefon'><center><h3><b>0532 694 00 79 </b></h3></center></div>
             
          </div>
          <div className='col-md-12 col-12 iletisimyazi'>
         
              <İletisimformm/>
          </div>
      </div>
    </div>
  )
}
