import React, { Component } from 'react'
import YouTube from 'react-youtube';
import ReactPlayer from "react-player";

export default class Youtubereklam extends Component {
  player = {};
  state = {
    video: {
      src: "https://www.youtube.com/watch?v=wIk6eyFyrlg",
      poster: "/1.png",
      controlsList: "nodownload",
    },
  };

  onPlayerReady(player) {
    this.player = player;
  }

  render() {
    return (
      <center>
        {" "}
        <div className="yooutubee">
          <ReactPlayer
            className="react-player"
            controls={false}
            autoplay={true}
            poster={this.state.video.poster}
            url={this.state.video.src}
            width="50%"
            height="400px"
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                  preload: "auto",
                  loop: "true",
                  controlsList: "nodownload",
                },
              },
            }}
            onReady={this.onPlayerReady.bind(this)}
          />
        </div>
      </center>
    );
  }
}