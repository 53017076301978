import React from 'react';
import Logo from '../Anasayfa/Logo/Logo';
import Resimboxs from './Resimboxs';
import Resim from './images/LV LOGO PNG (AHŞAPLI).png'

export default function Gorsel() {
  return (
  <div>
      <Logo/>
<Resimboxs/>

  </div>
  
  );
}
