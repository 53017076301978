import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import '../Görseller/styles.css'

export default function İletisimsayfasıformu() {
    const form = useRef();

  
    
    const sendEmail = (e) => {
        e.preventDefault();
  
        emailjs.sendForm('service_2mzxsqj', 'template_uquocw6', form.current, 'user_07CykNQLNZWcYNKYuBEeC')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };
  return (
    <div className='arkaplanform'>
        <div className='padi'>
        <center><p>نموذج اتصال لوفت فالنتين</p></center>
     <form ref={form} onSubmit={sendEmail}>
    <div className="form-group ff">
      
        <input type="text" name="to_name" className="form-control" placeholder='اسم' />
    </div>
    <div className="form-group ff">
      
        <input type="text" name="to_surname" className="form-control" placeholder='اللقب الخاص بك' />
    </div>
    <div className="form-group ff">
       
        <input type="email" name="user_email" className="form-control" aria-describedby="emailHelp" placeholder='بريد إلكتروني' />
    </div>
    <div className="form-group ff">
       
        <input type="text" name="to_cep" className="form-control" aria-describedby="emailHelp" placeholder='تليفون محمول' />
    </div>
    <div className="form-group ff">
       
        <textarea  name="message" className="form-control textform"placeholder='رسالة' rows="5"></textarea>
    </div>
    <div className='desadas'>
  <input type="checkbox" id="gizlilik" name="gizlilik" value="Gizlilik ilkelerini okudum, kampanyalardan haberdar olmak istiyorum"/>
  <label for="gizlilik">لقد قرأت سياسة الخصوصية ، أريد أن أكون على علم بالحملات.</label></div>
    
    <div className='bbbe'>
    <button type="submit" value="Send" className="btn btn-primary"><h3><b>يرسل</b></h3></button>
    </div>
    
    </form>
    </div>
    </div>
  )
}
