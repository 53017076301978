import React, { Component } from 'react'
import Resimone from './images/FOOTER-SON.png'
import './style.css'
import Popup from 'reactjs-popup';
import İletisim from '../../İletisim/İletisimppop'
export default class Bilgiform extends Component {
  render() {
    return (
      <div> 
        
         
          <Popup trigger={<img className='Biligtalep' src={Resimone}></img>} position="right center">
        <div><İletisim/></div>
        <div className='kapatt'><a href=''>KAPAT</a></div>
        </Popup>
          
          </div>
    )
  }
}
