import React, { Component } from 'react'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppupp';
import Resim from './images/Group 253.png'
export default class Loftvalentineone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'تصميم Loft Valentine Marriott لتلبية متطلبات الدرجة الأولى ، ويعد ضيوفه بحياة رائعة في أكثر المواقع شهرة في اسطنبول.',
      Video: '5 أمتار ارتفاع السقف',
      id:<Poppupp/>,
      Altyazi:"Loft Valentine Marriott أيضًا إمكانيات أسلوب حياة آمن للغاية وموفر للطاقة. يعد هذا المشروع .أحد أكبر وأفخم المشاريع المرغوبة والمرموقة على مستوى تركيا. وانعكاس حقيقي للتميز",
      

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg', id:<Poppupp/>, Title: 'تصميم Loft Valentine Marriott لتلبية متطلبات الدرجة الأولى ، ويعد ضيوفه بحياة رائعة في أكثر المواقع شهرة في اسطنبول. ', MyImagetwo: '/images/loftvalen.png', Video: '5 أمتار ارتفاع السقف', Altyazi:"Loft Valentine Marriott أيضًا إمكانيات أسلوب حياة آمن للغاية وموفر للطاقة. يعد هذا المشروع .أحد أكبر وأفخم المشاريع المرغوبة والمرموقة على مستوى تركيا. وانعكاس حقيقي للتميز " ,Altyazia:"", Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'يرفع مشروع  لوفت فالنتاين مستوى المعيشة الفاخرة من خلال الاستخدام المبتكر لتقنيات المباني الذكية. يضم هذا المبنى المثير للإعجاب  407 مكتبًا منزليًا فاخرًا للأجنحة المزدوجة و 220 غرفة فندقية فاخرة من ماريوت والعديد من المساحات التجارية الفاخرة. لا يعطي مشروع الأولوية لراحة النزيل فحسب ، بل يعرض ', MyImagetwo: '/images/Konsopt.png', Video: '',Altyazi:"Loft Valentine Marriott أيضًا إمكانيات أسلوب حياة آمن للغاية وموفر للطاقة. يعد هذا المشروع .أحد أكبر وأفخم المشاريع المرغوبة والمرموقة على مستوى تركيا. وانعكاس حقيقي للتميز",Altyazia:"" ,Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title:'بسهولة ،  مترًا 170 طوله يبلغ الذي Basın لطريق Loft Valentine Istanbul إلى الوصول يمكن Ekspres عن طريق ، إسطنبول في مكان ي أ من ، لمركزيا موقعه بفضل ، من المترو أو E5 أو TEM .طريق مرمرة شمال السريع', MyImagetwo: '/images/lokasyon.png',Altyazi:"", Altyazia:"متر 400  الأنفاقمترو ", Altyazibir:"كم 36 اسطنبول مطار ", Altyaziiki:"كم 5 تاتورك أ مطار", Altyaziuc:"كم 4 E5السريع الطريق ",Altyazidort:"كم 7 السريع ة مرمر شمال طريق",Altyazibes:"كم 5 بعد على البحر شاطئ. ",Altyaziyedi:"كم 8 سطنبول ا اوف مول",Altyazisekiz:"متر 800ستارسيتي مول",Altyazidokuz:"تقسيم 25 كم  ",Altyazion:"مضيق البوسفور 28 كم",Altyazionbir:"لها واجهة كاملة لطريق باسن اكسبريس وتقع بين تقاطعين" , Altyazioniki:"" ,Altyazioniki:" ",Altyazionuc:"" ,Altyaziondort:""});
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { id } = this.state;
    const {Altyazi}=this.state;
    const {Altyazia}=this.state;
    const {Altyazibir}=this.state;
    const {Altyaziiki}=this.state;
    const {Altyaziuc}=this.state;
    const {Altyazidort}=this.state;
    const {Altyazibes}=this.state;
    const {Altyazialtı}=this.state;
    const {Altyaziyedi}=this.state;
    const {Altyazisekiz}=this.state;
    const {Altyazidokuz}=this.state;
    const {Altyazion}=this.state;
    const {Altyazionbir}=this.state;
    const {Altyazioniki}=this.state;
    const {Altyazionuc}=this.state;
    const {Altyaziondort}=this.state;
    return (
      <div className='container-fluid tumsayfaloft'>
        <div className='row'>
          <div className='col-lg-1 loftcolbir'>
            <img  src={Resim}/>
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslik'>
              <button onClick={() => this.buuton('button-1')}><h5>Loft Valentine</h5></button>
              <button onClick={() => this.buuton('button-2')}><h5>مفهوم</h5></button>
              <button onClick={() => this.buuton('button-3')}><h5>موقع</h5></button>

            </div>
            <div className='ayrintiloft'><h4>{Title}</h4><br/>
            <p>{Altyazi}</p>
            <div className='wesd'>
            <h5>{Altyazia}</h5>
              <h5>{Altyazibir}</h5>
              <h5>{Altyaziiki}</h5>
              <h5>{Altyaziuc}</h5>
              <h5>{Altyazidort}</h5>
              <h5>{Altyazibes}</h5>
              <h5>{Altyazialtı}</h5>
              <h5>{Altyaziyedi}</h5>
              <h5>{Altyazisekiz}</h5>
              <h5>{Altyazidokuz}</h5>
              <h5>{Altyazion}</h5>
              <h5>{Altyazionbir}</h5>
              <h5>{Altyazioniki}</h5>
              <h5>{Altyazionuc}</h5>
              <h5>{Altyaziondort}</h5>
              
</div>

            </div>
            <div className=''><center><h5  className='loftvideo'>{id}</h5></center></div>


          </div>
          <div className='col-lg-5 sagloft'>
            <img src={MyImage} />
          </div>
        </div>
      </div>
    )
  }
}

