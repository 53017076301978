import React, { Component } from 'react'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppupp';
import Resim from './images/Group 253.png'
export default class Loftvalentineone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'Приглашаем вас в Luxe Designed Real Loft Experience!',
      Video: '',
      id:<Poppupp/>,
      Altyazi:"Разработанный в соответствии с требованиями мирового класса, Лофт Valentine Marriott обещает своим гостям роскошную жизнь в самом респектабельном месте Стамбула. Участок площадью 12000 м2, общей площадью 80 000 м2 и в407 роскошных двухуровневых апартаментов, в которых размещаются домашние офисы, отель Marriott на 220 номеров и различные коммерческие помещения, является одним из самых роскошных и прибыльных проектов Basin Express Road.",
      

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg', id:<Poppupp/>, Title: 'Приглашаем вас в Luxe Designed Real Loft Experience! ', MyImagetwo: '/images/loftvalen.png', Video: '5 метров высота потолка', Altyazi:"Разработанный в соответствии с требованиями мирового класса, Лофт Valentine Marriott обещает своим гостям роскошную жизнь в самом респектабельном месте Стамбула. Участок площадью 12000 м2, общей площадью 80 000 м2 и в407 роскошных двухуровневых апартаментов, в которых размещаются домашние офисы, отель Marriott на 220 номеров и различные коммерческие помещения, является одним из самых роскошных и прибыльных проектов Basin Express Road",Altyazia:"", Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Проект Loft Valentine Marriott поднимает планку роскошной жизни благодаря инновационному использованию интеллектуальных строительных технологий. Это впечатляющее здание может  похвастаться 407 роскошными домашними офисами в двухуровневых люксах, 220 роскошными гостиничными номерами Marriott и множеством роскошных коммерческих помещений.', MyImagetwo: '/images/Konsopt.png', Video: '',Altyazi:"Loft Valentine Marriott не только уделяет приоритетное внимание комфорту гостей, нои демонстрирует возможности безопасного и энергоэффективного образа жизни. Этот проект,являющийся одним из самых востребованных и престижных проектов , является истинным отражением превосходства в дизайне и функциональности.",Altyazia:"" ,Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title:'Концепция Loft Valentine Istanbul расположен в 170 метрах от дороги Basin Express находящийся вцентре Стамбула, до него легко добраться на метро,по автомагистрали E5, Kuzey Marmara Otoyolu, поэтому это облегчает путь до ваших апартаментов.', MyImagetwo: '/images/lokasyon.png',Altyazi:"", Altyazia:"400 метров до метро ", Altyazibir:"Аэропорт Стамбула 36 км", Altyaziiki:"Аэропорт имени Ататюрка в 5 км", Altyaziuc:"Автомагистраль Е5 4 км",Altyazidort:"Автомагистраль E80 7 км",Altyazibes:"6,5 км от берега моря",Altyaziyedi:"Kuzey Marmara Otoyolu 7 км",Altyazisekiz:"6,5 км от берега моря",Altyazidokuz:"Mall of Istanbul в 8 км",Altyazion:"Расстояние до торгового центра Starcity составляет 800 метров",Altyazionbir:"212 Торговый центр 5 км" , Altyazioniki:"Таксим 25 км" ,Altyazioniki:"Босфор 28 км ",Altyazionuc:"Также особенностью локации соединение трех основных трасс Стамбула." ,Altyaziondort:""    });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { id } = this.state;
    const {Altyazi}=this.state;
    const {Altyazia}=this.state;
    const {Altyazibir}=this.state;
    const {Altyaziiki}=this.state;
    const {Altyaziuc}=this.state;
    const {Altyazidort}=this.state;
    const {Altyazibes}=this.state;
    const {Altyazialtı}=this.state;
    const {Altyaziyedi}=this.state;
    const {Altyazisekiz}=this.state;
    const {Altyazidokuz}=this.state;
    const {Altyazion}=this.state;
    const {Altyazionbir}=this.state;
    const {Altyazioniki}=this.state;
    const {Altyazionuc}=this.state;
    const {Altyaziondort}=this.state;
    return (
      <div className='container-fluid tumsayfaloft'>
        <div className='row'>
          <div className='col-lg-1 loftcolbir'>
            <img  src={Resim}/>
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslik'>
              <button onClick={() => this.buuton('button-1')}><h5>Лофт Валентайн</h5></button>
              <button onClick={() => this.buuton('button-2')}><h5>концепция</h5></button>
              <button onClick={() => this.buuton('button-3')}><h5>Расположение</h5></button>

            </div>
            <div className='ayrintiloft'><h4>{Title}</h4><br/>
            <p>{Altyazi}</p>
            <div className='wesd'>
            <h5>{Altyazia}</h5>
              <h5>{Altyazibir}</h5>
              <h5>{Altyaziiki}</h5>
              <h5>{Altyaziuc}</h5>
              <h5>{Altyazidort}</h5>
              <h5>{Altyazibes}</h5>
              <h5>{Altyazialtı}</h5>
              <h5>{Altyaziyedi}</h5>
              <h5>{Altyazisekiz}</h5>
              <h5>{Altyazidokuz}</h5>
              <h5>{Altyazion}</h5>
              <h5>{Altyazionbir}</h5>
              <h5>{Altyazioniki}</h5>
              <h5>{Altyazionuc}</h5>
              <h5>{Altyaziondort}</h5>
              
</div>

            </div>
            <div className=''><center><h5  className='loftvideo'>{id}</h5></center></div>


          </div>
          <div className='col-lg-5 sagloft'>
            <img src={MyImage} />
          </div>
        </div>
      </div>
    )
  }
}
