import React, { Component } from 'react'
import './style.css'
import Logo from '../Anasayfa/Logo/Logo';
import Gallery from 'react-grid-gallery';
import Okresim from './images/Okcizgi.png'


export default class Katplanimobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/AD.png'
    }
  }
  hello = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/AD.png', MyText: '1' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/BB.png', MyText: '1' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/CD.png', MyText: '1' });
    }
    if (tabContentID == 'button-4') {
      this.setState({ MyImage: '/images/AGD.png', MyText: '1' });
    }
    if (tabContentID == 'button-5') {
      this.setState({ MyImage: '/images/AKD.png', MyText: '1' });
    }
    if (tabContentID == 'button-6') {
      this.setState({ MyImage: '/images/BGB.png', MyText: '1' });
    }
    if (tabContentID == 'button-7') {
      this.setState({ MyImage: '/images/BKB.png', MyText: '1' });
    }

  }
  render() {
    const IMAGES =
      [{
        src: this.state.MyImage,
        thumbnail: this.state.MyImage,
        isSelected: false,
        caption: "loftvalentine"
      },]
    const { MyImage } = this.state;
    return (
      <div className='body'>
        <Logo />
        <div className='container ddmobil'>
          <div className='row'>
          
        
          <h4 className='mobilkatp'>پلان طبقات</h4>
            
            <div className='container daierlermobil'>
           
<div className='row'>
              <div className='col-6'>
                <p><button onClick={() => this.hello('button-1')}><h5>TİP 1 - AD</h5></button></p>
                <p><button onClick={() => this.hello('button-2')}><h5>TİP 1 - BB</h5></button></p>
                <p><button onClick={() => this.hello('button-3')}><h5>TİP 1 - CD</h5></button></p>
                </div>
               
                <div className='col-6'>
                <p> <button onClick={() => this.hello('button-4')}><h5>TİP 1 - AGD</h5></button></p>
                <p> <button onClick={() => this.hello('button-5')}><h5>TİP 2 - AKD</h5></button></p>
                <p><button onClick={() => this.hello('button-6')}><h5>TİP 1 - BGB</h5></button></p>
                <p> <button onClick={() => this.hello('button-7')}><h5>TİP 1 - BKB</h5></button></p>
                </div>

            </div>
            </div>


            <div className='col-lg-8 resimmobil'><Gallery images={IMAGES} /></div>

          </div>
        </div>
      </div>

    )
  }
}


