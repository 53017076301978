import React, { Component } from 'react';
import Loftvalentine from '../componentRus/Loftvalentine/Loftvalentineone';
import Logo from '../componentRus/Anasayfa/Logo/Logo';
import Navbarjs from '../componentRus/Anasayfa/navbar/Navbarjs';
import Navbermobil from '../componentRus/Navbarmabil/Navbermobil';
import Mobilfooter from '../componentRus/mobilfooter/Mobilfooter';
import Mobililetisim from '../componentRus/İletisim/Mobililetisimsiyah';
import MobilLogo from '../componentRus/Anasayfa/mobillogo/MobilLogo'
import Loftvalentinemobil from '../componentRus/Loftvalentine/Loftvalentinemobil';
import Linkler from '../container/Linkler';

export default class Loftvalentinepage extends Component {
  render() {
    return (
    <div className='hepsi'>
      <Navbermobil/>
      <MobilLogo/>
        <Logo/>
        <Linkler/>
        <Loftvalentine/>
        <Loftvalentinemobil/>
        <Navbarjs/>
        <Mobililetisim/>
        <Mobilfooter/>
        

        
    </div>);
  }
}
