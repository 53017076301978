import React, { Component } from 'react'
import Navbarjs from '../component/Anasayfa/navbar/Navbarjs'
import Header from '../component/Anasayfa/Header/Header'
import Logo from '../component/Anasayfa/Logo/Logo'
import Navbermobil from '../component/Navbarmabil/Navbermobil'
import Mobililetisim from '../component/İletisim/Mobilhomeiletisim'
import Mobilfooter from '../component/mobilfooter/Mobilfooter'
import MobilLogo from '../component/Anasayfa/mobillogo/MobilLogo'
import Linkler from '../container/Linkler'


export default class Home extends Component {
    render() {
        return (
            <div className='homemobil'> 
<MobilLogo/>
<Linkler/>
                <Navbermobil/>
                <Logo/>
                <Navbarjs/>
                <Header/>
                <Mobililetisim/>
                <Mobilfooter/>
            </div>
        )
    }
}
