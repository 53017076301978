import React, { Component } from 'react';
import data from './data.json'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppumpmobil';
export default class Loftvalentinemobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/fas.png',
      Title: 'شما به تجربه Loft Real Designed Luxe دعوت شده اید!',
      Video: 'ارتفاع سقف 5 متر',
      id:<Poppupp/>

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg',id:<Poppupp/>, Title: 'شما به تجربه Loft Real Designed Luxe دعوت شده اید! ', MyImagetwo: '/images/fas.png', Video: '5 Metre Tavan Yüksekliği' });
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Loft Valentine با خطوط معماری مدرن، مناطق کاربری وسیع و جادار، جزئیات طراحی داخلی مجلل و راحت، قول می دهد زندگی فراتر از رویاهای شما را به شما ارائه دهد.', MyImagetwo: '/images/fass.png', Video: '' });
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title: ' Loft Valentine Istanbul، که دارای 170 متر نما به جاده Basın Ekspres است، به لطف موقعیت مرکزی آن، از مترو، E5، TEM یا بزرگراه مرمره شمالی، به راحتی از هر نقطه استانبول قابل دسترسی است.', MyImagetwo: '/images/fasss.png' });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { Video } = this.state;
    const { id } = this.state;
    return (
      <div className='container-fluid loftmobill'>
        <div className='row'>



        <div className='col-lg-6 sagloftmobil'>
            <img src={MyImage} />
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslikmobil'>
              <button onClick={() => this.buuton('button-1')}><p>LoftValentine</p></button>
              <button onClick={() => this.buuton('button-2')}><p>مفهوم</p></button>
              <button onClick={() => this.buuton('button-3')}><p>محلn</p></button>

            </div>
            <div className='ayrintiloftmobil'><h4>{Title}</h4>
              <img src={MyImagetwo} />


            </div>
            <div className=''><center><h5 className='loftvideo'>{id}</h5></center></div>


          </div>
        </div>

      </div>

    );
  }
}