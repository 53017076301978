import React, { Component } from "react";
import "./Hakkiicerik.css";
import AnaResim from "./images/Ruz-inşaat Logo.png";
import AnaResimm from "./images/yemenoglu.jpg";

export default class hakkiicerik extends Component {
  render() {
   
    const baslik = "About";
    const yazi1 = "Istanbul's thriving population of almost 18 million and high demand from domestic and foreign buyers positions Loft Valentine for significant value growth. The city's limited land supply, combined with the project's unique location and exceptional quality, make Loft Valentine a smart investment opportunity.";
    const yazi2 = "Loft Valentine is an extraordinary development project that combines luxury, technology and comfort in the heart of Istanbul. Located on the prestigious Basin Express Road, the project offers unrivaled access to all major highways and Istanbul Airport, just 35 km away";
     const yazi3 = "The project offers spacious, impeccably designed 1+1 duplex apartments, perfect for home offices, work spaces or urban residences. 90% of the project consists of these versatile units, and Loft Valentine appeals to the dynamic lifestyles of professionals and city dwellers."

    return (
      <div>
        <div className="container">
          <div className="lofthakki">
       <h2 className="baslikdd">{baslik}</h2>
      <lu>
        <li>{yazi1}</li> 
        <li>{yazi2}</li>
        <li>{yazi3}</li>
        
       
      
      </lu>

      
</div>
<div className="container ilkk">
<lu>
<h3 className="testdenemee">Highlights and Amenities</h3>
        <li>High Investment Potential: Istanbul's increasing population and limited land supply make Loft Valentine a strategic investment.</li>
        <li>Unique Location: Located on Basın Ekspres Road, the only road connecting all major highways in Istanbul, providing excellent connectivity</li>
        <li>Versatile Living Spaces: Stylish 1+1 duplex apartments suitable for a variety of uses, designed to meet the needs of modern professionals</li>
        <li>Luxury Amenities: Includes a state-of-the-art fitness center, yoga studio, green roof, community garden, and five-star, 24/7 concierge service.</li>
<li>You can access the presentation file via the Google drive address below.</li>
<p classNam="adenemers"><a  href="https://drive.google.com/drive/folders/1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV"><p className="peer">https://drive.google.com/drive/folders /1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV</p></a></p>
      
      
</lu>
</div>
      </div>
      </div>
    );
  }
}
