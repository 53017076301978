
import '../Görseller/styles.css'
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
export default function İletisimppop() {
    const form = useRef();

  
    
  const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_2mzxsqj', 'template_uquocw6', form.current, 'user_07CykNQLNZWcYNKYuBEeC')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      e.target.reset()
  };
  return (
    <div className='popiletisim'>
    <div className='padii'>
    <center><p>LOFT VALENTINE İLETİŞİM FORMU</p></center>
 <form ref={form} onSubmit={sendEmail}>
<div className="form-group">
  
    <input type="text" name="to_name" className="form-control" placeholder='İsim' />
</div>
<div className="form-group ff">
  
    <input type="text" name="to_surname" className="form-control" placeholder='Soyadınız' />
</div>
<div className="form-group ff">
   
    <input type="email" name="user_email" className="form-control" aria-describedby="emailHelp" placeholder='E-mail' />
</div>
<div className="form-group ff">
   
    <input type="text" name="to_cep" className="form-control"  placeholder='Cep Telefonu' />
</div>
<div className="form-group ff">
   
    <textarea  name="İleti" className="form-control textform"placeholder='Mesaj' rows="5"></textarea>
</div>
<div className='desadas'>
  <input type="checkbox" id="gizlilik" name="gizlilik" value="Gizlilik ilkelerini okudum, kampanyalardan haberdar olmak istiyorum"/>
   <label for="gizlilik"><p> &nbsp;&nbsp;Gizlilik ilkelerini okudum, kampanyalardan haberdar olmak istiyorum.</p></label></div>
  

<div className='bbbe'>
<button type="submit" value="Send" className="btn btn-primary"><h3><b>Gönder</b></h3></button>
</div>

</form>
</div>
</div>
  )
}
