import React, { Component } from 'react'
import Popup from 'reactjs-popup';

import './style.css'
export default class Popupgizlilik extends Component {
  render() {
    return (
        <div className=''><Popup trigger={<b>Gizlilik Sözleşmesi</b>} position="right center">
        <div className='gizlilikyazi'>
          <div className='scrol'>
           <h5>Gizlilik Sözleşmesi</h5><p>
LOFT VALENTINE sizlerin kişisel gizliliğini korumaya özen göstermektedir.
Bu gizlilik politikası LOFT VALENTINE tarafından bu web sitesinde toplanan bilgi türlerini ve bunların nasıl korunup saklandığını ana hatlarıyla açıklamaktadır.
LOFT VALENTINE, ziyaretçi tarafından gönüllü olarak verilmediği sürece hiçbir LOFT VALENTINE web sitesi kullanıcısı hakkında kişisel bilgileri toplamamaktadır.
Bu gizlilik politikası kapsamında kural olarak ziyaretçilerimiz tarafından sitemize sunulan kişisel veriler (adınız, elektronik posta adresiniz, telefon numaralarınız) üçüncü kişilerle paylaşılmamakta, satılmamakta, kiralanmamakta ve başka şekilde kullandırılmamaktadır.
</p><p>
Kişisel Bilgilerin Toplanması
LOFT VALENTINE web sitesini kullanmak, gönüllü olarak kişisel bilgileri vermek bu sitede ilan edilen mevcut gizlilik politikasının ve şartlarının kabul edildiği anlamı taşımaktadır.
</p><p>Bilgi Toplamanın Amacı ve Kullanılış Şekli</p>

<p>LOFT VALENTINE sizin izninizle bilgilerinizi aşağıdaki amaçlar için kullanmaktadır;</p>
<p>• Sizi elektronik posta, SMS, MMS ve telefon ile bilgilendirmek, kampanyalardan haberdar etmek,</p>
    
    <p>• Ziyaretçi profillerimizi belirlemek,</p>
    <p>• İstek, talep ve şikâyetleri değerlendirmek</p>



<p>Kişisel bilgiler ve bu veriler sadece belirtilen amaçlar için ve yasal soruşturmalar esnasında mahkeme kararı gereği veya yasal prosedürler gerektirdiği takdirde kullanılmaktadır.
Kişisel Olmayan Diğer Kullanıcı Bilgilerinin Toplanması
LOFT VALENTINE, ziyaretçilerin web sitesindeki dolaşımları sırasında, kullanıcı yoğunluğu, ziyaret edilen bölümler, tıklanan alanlar gibi özellikleri otomatik ve isimsiz olarak toplamaktadır. Bu veriler sadece bölümlerin, farklı alanların izlenme, takip edilme oranlarının tespiti için kullanılmakta ve herhangi bir ziyaretçinin kişisel bilgileri ile ilişkilendirilmemektedir. Sayfaların nereden geçerek ya da ne şekilde ziyaret edildiğini belirleyen "cookie" (çerez) adlı teknolojinin kullanıldığı durumlarda aynı şekilde sitenin farklı alanlarının izlenme, tıklanma oranlarının belirlenmesinde istatistiki veri olarak toplanmakta olup kişiye özel yapılan bir uygulama değildir. Bu teknoloji ile amaçlanan kullanıcıların daha sıklıkla ziyaret ettikleri bölümlere ait içeriği, siteye ilk ziyaretinden itibaren kullanıcı için daha kolay ulaşılabilir kılmaktır.</p>
<p>Bilgi Güvenliği
Kişisel bilgilerinizin güvenliğini sağlamak için gerekli önlemler alınmaktadır. Türkiye Cumhuriyeti 6698 sayılı Kişisel Verilerin Korunması kanunu kapsamında koruyacağımızı taahhüt ederiz. LOFT VALENTINE olarak ticari faaliyetlerimize devam ettiğimiz sürece, bu yasa kapsamında kişisel verilerinizi; sizi, hazırladığımız özel kampanyalardan haberdar edebilmek için, yurt içi veya yurt dışında güvenli veri merkezlerinde saklayıp şirketimizin gizlilik anlaşması yaptığı iş ortaklarıyla paylaşabileceğiz.
Site Kullanım Şartları
LOFT VALENTINE web sitesini zaman zaman düzenlediği kampanyaları duyurmak, ziyaretçilerin ilgisini çekebileceğini düşündüğü konular, içerikler farklı hizmet ve çalışmalardan ziyaretçileri haberdar etmek amacıyla kullanmaktadır. LOFT VALENTINE web sitesi içeriğini burada yayımladığı hüküm ve şartları önceden haber vermek zorunda olmaksızın tek taraflı olarak değiştirme ve güncelleme hakkına sahiptir.

Politikamızdaki Değişiklikler
LOFT VALENTINE 'un, bu Gizlilik Politikasını değiştirme veya iptal etme hakkı saklıdır.

Bize Ulaşın
Bu Gizlilik Politikası ile ilgili herhangi bir görüş bildirmek, soru yöneltmek veya Gizlilik Politikasının ihlali ile ilgili haber vermek isterseniz, Bize Ulaşın linkinden faydalanabilirsiniz. LOFT VALENTINE bu talepleri karşılamak üzere gerekli adımları atacaktır.
LOFT VALENTINE web sitesini ziyaret eden her bir ziyaretçi bütün bu şartları okumuş ve kabul etmiş sayılır.</p>

</div>
</div>
        <div className='kapat'><a href=''>X</a></div>
        </Popup></div>
    )
  }
}
