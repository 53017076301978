import React, { Component } from "react";
import "./Hakkiicerik.css";
import AnaResim from "./images/Ruz-inşaat Logo.png";
import AnaResimm from "./images/yemenoglu.jpg";

export default class hakkiicerik extends Component {
  render() {
    
    const baslik = "معلومات عنا";
    const yazi1 = "ن عدد سكان إسطنبول المزدهر الذي يبلغ حوالي 18 مليون نسمة وارتفاع الطلب من المشترين المحليين والأجانب يجعل Loft Valentine يحقق نموًا كبيرًا في القيمة. إن محدودية الأراضي في المدينة، إلى جانب الموقع الفريد للمشروع والجودة الاستثنائية، تجعل من Loft Valentine فرصة استثمارية ذكية.";
    const yazi2 = "لوفت فالنتين هو مشروع تطوير استثنائي يجمع بين الفخامة والتكنولوجيا والراحة في قلب اسطنبول. يقع المشروع على طريق باسن إكسبريس المرموق، ويوفر وصولاً لا مثيل له إلى جميع الطرق السريعة الرئيسية ومطار إسطنبول الذي يبعد 35 كم فقط";
     const yazi3 = "قدم المشروع شقق دوبلكس 1+1 فسيحة ومصممة بشكل مثالي، مثالية للمكاتب المنزلية أو أماكن العمل أو المساكن الحضرية. يتكون 90% من المشروع من هذه الوحدات متعددة الاستخدامات، ويناسب Loft Valentine أنماط الحياة الديناميكية للمحترفين وسكان المدن."

    return (
      <div>
        <div className="container">
          <div className="lofthakki">
       <h2 className="baslikdd">{baslik}</h2>
      <lu>
        <li>{yazi1}</li> 
        <li>{yazi2}</li>
        <li>{yazi3}</li>
        
       
      
      </lu>

      
</div>
<div className="container ilkk">
<lu>
<h3 className="testdenemee">أبرز وسائل الراحة</h3>
        <li>إمكانات استثمارية عالية: إن تزايد عدد السكان في إسطنبول ومحدودية المعروض من الأراضي يجعل من Loft Valentine استثمارًا استراتيجيًا.</li>
        <li>موقع فريد: يقع على طريق باسن اكسبريس، وهو الطريق الوحيد الذي يربط جميع الطرق السريعة الرئيسية في اسطنبول، مما يوفر اتصالاً ممتازًا</li>
        <li>مساحات معيشة متعددة الاستخدامات: شقق دوبلكس أنيقة 1+1 مناسبة لمجموعة متنوعة من الاستخدامات، ومصممة لتلبية احتياجات المهنيين المعاصرين</li>
        <li>وسائل الراحة الفاخرة: تشمل مركزًا للياقة البدنية على أحدث طراز واستوديو يوجا وسقفًا أخضر وحديقة مجتمعية وخدمة كونسيرج من فئة الخمس نجوم على مدار الساعة طوال أيام الأسبوع.</li>
<li>يمكنك الوصول إلى ملف العرض التقديمي عبر عنوان Google Drive أدناه.</li>
<p classNam="adenemers"><a  href="https://drive.google.com/drive/folders/1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV"><p className="peer">https://drive.google.com/drive/folders /1KrQnLLLtsb0ki8yn-BEia6o5giRDxwwV</p></a></p>
      
      
</lu>
</div>
      </div>
      </div>
    );
  }
}
