import React, { Component } from 'react'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppupp';
import Resim from './images/Group 253.png'
export default class Loftvalentineone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'Lüx Dizayn Edilmiş Gerçek Loft Yaşantısına davetlisiniz!',
      Video: '5 Metre Tavan Yüksekliği',
      id:<Poppupp/>,
      Altyazi:"Birinci sınıf gereksinimleri karşılamak için tasarlanan Loft Valentine İstanbul'un en gözde konumunda konuklarına ihtişamlı yaşama vaat ediyor",
      

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg', id:<Poppupp/>, Title: 'Lüx Dizayn Edilmiş Gerçek Loft Yaşantısına davetlisiniz! ', MyImagetwo: '/images/loftvalen.png', Video: '5 Metre Tavan Yüksekliği', Altyazi:"Birinci sınıf gereksinimleri karşılamak için tasarlanan Loft Valentine İstanbul'un en gözde konumunda konuklarına ihtişamlı yaşama vaat ediyor",Altyazia:"", Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'Modern mimari çizgileri, geniş ve ferah kullanım alanları,lüks ve aynı zamanda konforlu iç tasarım detayları ile Loft Valentine,size hayallerinizin ötesinde bir  yaşam sunmayı vaat ediyor.', MyImagetwo: '/images/Konsopt.png', Video: '',Altyazi:"Yaşam standartlarını yükselten, mevcut akıllı bina teknolojileriyle misafirlerinin konforunu en üst seviyeye çıkaran, ve aynı zamanda enerji tasarruflu bir yaşamın mümkün olabileceğini kanıtlayan bir yapı, Loft Valentine.",Altyazia:"" ,Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title:' Basın Ekspres Yoluna 170 metre cephesi olan Loft Valentine İstanbul, merkezi konumuyla, Metro, E5, TEM veya Kuzey Marmara Otoyolundan, dolayısıyla İstanbul’un her yerinden kolayca ulaşılabiliyor.', MyImagetwo: '/images/lokasyon.png',Altyazi:"", Altyazia:"Metro 400 metre ", Altyazibir:"İstanbul Havalimanı 36 km ", Altyaziiki:"Atatürk Havalimanı 5 km", Altyaziuc:"Otoyol E5 4 km",Altyazidort:"Otoyol Kuzey Marmara 7 km",Altyazibes:"Deniz kıyısına 6,5 km",Altyaziyedi:"Otoyol Kuzey Marmara 7 km",Altyazisekiz:"Deniz kıyısına 6,5 km",Altyazidokuz:"Mall of Istanbul 8 km ",Altyazion:"Starcity AVM 800 metre ",Altyazionbir:"212 AVM 5 km" , Altyazioniki:"Kapalı Çarşı 20 km" ,Altyazioniki:"Taksim 25 km ",Altyazionuc:"Boğaz 28 km " ,Altyaziondort:"Basın Ekspres Yolu’na tam cepheli olup 2 kavşak arası"    });
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { id } = this.state;
    const {Altyazi}=this.state;
    const {Altyazia}=this.state;
    const {Altyazibir}=this.state;
    const {Altyaziiki}=this.state;
    const {Altyaziuc}=this.state;
    const {Altyazidort}=this.state;
    const {Altyazibes}=this.state;
    const {Altyazialtı}=this.state;
    const {Altyaziyedi}=this.state;
    const {Altyazisekiz}=this.state;
    const {Altyazidokuz}=this.state;
    const {Altyazion}=this.state;
    const {Altyazionbir}=this.state;
    const {Altyazioniki}=this.state;
    const {Altyazionuc}=this.state;
    const {Altyaziondort}=this.state;
    return (
      <div className='container-fluid tumsayfaloft'>
        <div className='row'>
          <div className='col-lg-1 loftcolbir'>
            <img  src={Resim}/>
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslik'>
              <button onClick={() => this.buuton('button-1')}><h5>Loft Valentine</h5></button>
              <button onClick={() => this.buuton('button-2')}><h5>Konsept</h5></button>
              <button onClick={() => this.buuton('button-3')}><h5>Lokasyon</h5></button>

            </div>
            <div className='ayrintiloft'><h4>{Title}</h4><br/>
            <p>{Altyazi}</p>
            <div className='wesd'>
            <h5>{Altyazia}</h5>
              <h5>{Altyazibir}</h5>
              <h5>{Altyaziiki}</h5>
              <h5>{Altyaziuc}</h5>
              <h5>{Altyazidort}</h5>
              <h5>{Altyazibes}</h5>
              <h5>{Altyazialtı}</h5>
              <h5>{Altyaziyedi}</h5>
              <h5>{Altyazisekiz}</h5>
              <h5>{Altyazidokuz}</h5>
              <h5>{Altyazion}</h5>
              <h5>{Altyazionbir}</h5>
              <h5>{Altyazioniki}</h5>
              <h5>{Altyazionuc}</h5>
              <h5>{Altyaziondort}</h5>
              
</div>

            </div>
            <div className=''><center><h5  className='loftvideo'>{id}</h5></center></div>


          </div>
          <div className='col-lg-5 sagloft'>
            <img src={MyImage} />
          </div>
        </div>
      </div>
    )
  }
}
