import React, { Component } from 'react'
import İnfo from '../component/Anasayfa/İİnfo/İnfo'
import İnfomobil from '../component/Anasayfa/İİnfo/İnfomobil'
export default class İnfohome extends Component {
  render() {
    return (
      <div><İnfo/>
      <İnfomobil/>
      </div>
    )
  }
}
