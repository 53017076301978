import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import '../Logo/style.css'
import Resim from './images/Component 24 – 7.png'
import { Link } from 'react-router-dom'

export default class İnfo extends Component {
  player = {}
  state = {
      video: {
          src: "/images/web.mp4",
          poster: "/1.png",
          controls:"true",
          autoplay:"true",
          playing: true 
      }
  }
 
  onPlayerReady(player){
      this.player = player;
  }

  render() {
    
    return (
      <center> <div className='info'>
        <div className='infovideo'>
        <ReactPlayer
            className="react-player"
            controls={false}
            autoplay={true}
            poster={this.state.video.poster}
            url={this.state.video.src}
            width="40%"
            height="40%"
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                  preload: "auto",
                  loop: "true",
                  controlsList: "nodownload",
                },
              },
            }}
            onReady={this.onPlayerReady.bind(this)}
          />
    </div>
    <div className="inforesim">
    <Link to='/anasayfa'style={{ textDecoration: 'none' }}><img src={Resim}/> </Link>
      
    </div>
    
    </div></center>
      
    )
  }
}
