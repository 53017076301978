import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import İletisim from '../../İletisim/İletisimppop'
import { FiMail } from "react-icons/fi";
export default class Popuplinkmail extends Component {
  render() {
    return (
        <Popup trigger={<h3><FiMail/></h3>} position="right center">
        <div><İletisim/></div>
        <div className='kapatt'><a href=''>KAPAT</a></div>
        </Popup>
    )
  }
}
