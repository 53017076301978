import React, { Component } from 'react'
import './styles.css'
import Poppupp from '../Anasayfa/poppup/Poppupp';
import Resim from './images/Group 253.png'
export default class Loftvalentineone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyImage: '/images/loft1.jpeg',
      MyImagetwo: '/images/loftvalen.png',
      Title: 'You are invited to Luxe Designed Real Loft Experience!',
      Video: '5 Meters Ceiling Height',
      id:<Poppupp/>,
      Altyazi:"Designed to meet first-class requirements, Loft Valentine promises its guests a magnificent life in Istanbul's most popular location.",
      

    }
  }
  buuton = (tabContentID) => {
    if (tabContentID == 'button-1') {
      this.setState({ MyImage: '/images/loft1.jpeg', id:<Poppupp/>, Title: 'You are invited to Luxe Designed Real Loft Experience! ', MyImagetwo: '/images/loftvalen.png', Video: '5 Metre Tavan Yüksekliği', Altyazi:"Designed to meet first-class requirements, Loft Valentine promises its guests a magnificent life in Istanbul's most popular location",Altyazia:"", Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-2') {
      this.setState({ MyImage: '/images/Konsoptsag.jpg',id:"", Title: 'With its modern architectural lines, wide and spacious usage areas, luxurious and comfortable interior design details, Loft Valentine promises to offer you a life beyond your dreams.', MyImagetwo: '/images/Konsopt.png', Video: '',Altyazi:"Loft Valentine is a building that raises its standards, maximizes the comfort of its guests with existing smart building technologies, and also proves that an energy-efficient life is possible.",Altyazia:"" ,Altyazibir:"",Altyaziiki:"",Altyaziuc:"",Altyazidort:"",Altyazibes:"",Altyazialtı:"",Altyaziyedi:"",Altyazisekiz:"",Altyazidokuz:"" ,Altyazion:"",Altyazionbir:"",Altyazioniki:"",Altyazionuc:"",Altyaziondort:""});
    }
    if (tabContentID == 'button-3') {
      this.setState({ MyImage: '/images/lokasyon.jpg',id:"", Title:'Loft Valentine Istanbul, which has a 170-meter frontage to the Basın Ekspres Road, can be easily reached from anywhere in Istanbul, thanks to its central location, from the Metro, E5, TEM or Northern Marmara Highway.', MyImagetwo: '/images/lokasyon.png',Altyazi:"", Altyazia:"Metro 400 meters", Altyazibir:"Istanbul Airport 36 km ", Altyaziiki:"Ataturk Airport 5 km", Altyaziuc:"Highway E5 4 km",Altyazidort:"Highway North Marmara 7 km",Altyazibes:"6.5 km to the seaside",Altyaziyedi:"Highway North Marmara 7 km",Altyazisekiz:"6.5 km to the seaside",Altyazidokuz:"Mall of Istanbul 8 km ",Altyazion:"Starcity AVM 800 meters",Altyazionbir:"212 AVM 5 km" , Altyazioniki:"Taksim 25 km" ,Altyazioniki:"Bosphorus 28 km ",Altyazionuc:"It has full frontage to Basın Ekspres Road and is between 2 intersections and high roads" ,Altyaziondort:""});
    }

  }

  render() {
    const { MyImage } = this.state;
    const { MyImagetwo } = this.state;
    const { Title } = this.state;
    const { id } = this.state;
    const {Altyazi}=this.state;
    const {Altyazia}=this.state;
    const {Altyazibir}=this.state;
    const {Altyaziiki}=this.state;
    const {Altyaziuc}=this.state;
    const {Altyazidort}=this.state;
    const {Altyazibes}=this.state;
    const {Altyazialtı}=this.state;
    const {Altyaziyedi}=this.state;
    const {Altyazisekiz}=this.state;
    const {Altyazidokuz}=this.state;
    const {Altyazion}=this.state;
    const {Altyazionbir}=this.state;
    const {Altyazioniki}=this.state;
    const {Altyazionuc}=this.state;
    const {Altyaziondort}=this.state;
    return (
      <div className='container-fluid tumsayfaloft'>
        <div className='row'>
          <div className='col-lg-1 loftcolbir'>
            <img  src={Resim}/>
          </div>
          <div className='col-lg-6  saglof'>
            <div className='anabaslik'>
              <button onClick={() => this.buuton('button-1')}><h5>Loft Valentine</h5></button>
              <button onClick={() => this.buuton('button-2')}><h5>Concept</h5></button>
              <button onClick={() => this.buuton('button-3')}><h5>Location</h5></button>

            </div>
            <div className='ayrintiloft'><h4>{Title}</h4><br/>
            <p>{Altyazi}</p>
            <div className='wesd'>
            <h5>{Altyazia}</h5>
              <h5>{Altyazibir}</h5>
              <h5>{Altyaziiki}</h5>
              <h5>{Altyaziuc}</h5>
              <h5>{Altyazidort}</h5>
              <h5>{Altyazibes}</h5>
              <h5>{Altyazialtı}</h5>
              <h5>{Altyaziyedi}</h5>
              <h5>{Altyazisekiz}</h5>
              <h5>{Altyazidokuz}</h5>
              <h5>{Altyazion}</h5>
              <h5>{Altyazionbir}</h5>
              <h5>{Altyazioniki}</h5>
              <h5>{Altyazionuc}</h5>
              <h5>{Altyaziondort}</h5>
              
</div>

            </div>
            <div className=''><center><h5  className='loftvideo'>{id}</h5></center></div>


          </div>
          <div className='col-lg-5 sagloft'>
            <img src={MyImage} />
          </div>
        </div>
      </div>
    )
  }
}
