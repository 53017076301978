import React from 'react'
import Logoo from '../Logo/images/logo.png'
import { BsCameraReelsFill } from "react-icons/bs";
import '../Logo/style.css'
import PopupReklam from '../poppup/Popıpreklammobil';
function MobilLogo() {
  return (
      <div className='mobilupbar'>
<center><div className='mobillogo'><img src={Logoo}/></div></center>
          <div className='container mobilupcontainer'>
              <div className='row'>
                  <div className='col-xs-6 mobilsagkamera'><PopupReklam/></div>
                  <div className='col-xs-6'></div>
              </div>
          </div>
      </div>
    
  )
}

export default MobilLogo


